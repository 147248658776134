import * as React from "react";

import { Divider, Modal, Table } from "antd";

import * as lensesLib from "@lib/lenses/lenses";
import Link from "antd/lib/typography/Link";
import { AttributeTypeMap } from "@lib/box/attribute-type";
import * as attributeTypeLib from "@lib/box/attribute-type";

const confirm = Modal.confirm;

interface AttributeTypeVisibilityTableProps {
	onEdit: (key: string) => void;
	onDelete: (key: string) => void;
	attributeTypeVisibilityMap: attributeTypeLib.AttributeTypeVisibilityMap | null;
	attributeTypes : AttributeTypeMap;
	canEdit: boolean;
	canDelete: boolean;
}

interface AttributeTypeRow {
	key: string;
	name: string;
	value: boolean;
}

const AttributeTypeVisibilityTable : React.FC<AttributeTypeVisibilityTableProps> = (props) => {
	// Build the box type list items

	const handleEdit = (index: string) => {
		// Notify the callback that the box type should be edited
		props.onEdit(index);
	};

	const handleDelete = (key: string) => {
		// Notify the callback that the box type should be removed
		props.onDelete(key);
	};

	const buildRows = (
		map: attributeTypeLib.AttributeTypeVisibilityMap | null
	) : AttributeTypeRow[] => {
		// If we don't have a box map there's no list items
		if (!map) {
			return [];
		}

		// Render each of the box types
		const rows = Object.keys(map).map(
			(key, index) => {
				const attributeTypeExpression = map[key];
				// Get the box type
				// Build the box type list item
				const row: AttributeTypeRow = {
					key: key,
					name: props.attributeTypes[key].name,
					value: attributeTypeLib.getAttributeVisibility(attributeTypeExpression)
				};

				return row;
			}
		);

		return rows;
	};

	const queries: AttributeTypeRow[] = buildRows(
		props.attributeTypeVisibilityMap
	);

	const queryTableColumns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Value",
			dataIndex: "value",
			key: "value",
		},
		{
			title: "Action",
			key: "action",
			render: (text: string, record: AttributeTypeRow) => (
				<span>
					<Link onClick={() => {
						(handleEdit(record.key))
					}}>
						Edit
					</Link>
					<Divider type="vertical" />
					<Link
						onClick={() => {
							confirm({
								title:
									"Are you sure delete this Attribute Type Visibility Item?",
								okText: "Yes",
								okType: "danger",
								cancelText: "No",
								zIndex: 9999999,
								onOk: () => { handleDelete(record.key) },
								onCancel: () => { },
							});
						}}
					>
						Delete
					</Link>
				</span>
			),
		},
	];

	return (
		<Table
			dataSource={queries}
			pagination={false}
			columns={queryTableColumns}
		/>
	);
}

export default AttributeTypeVisibilityTable;