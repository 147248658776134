import {
	AttributeMap,
} from '@lib/box/attribute'

import * as attributeTypeLib from '@lib/box/attribute-type'

import {
	Properties,
	createBoxDefaultProperties,
	ChildLayout,
} from '@lib/box/box-properties'
import {
	BoxTypeMap,
	BoxTypeVisibilityMap,
	getBoxTypeAttributeTypeCacheForType,
	setAttributeTypeVisibilityCacheEnabled,
	getAttributeTypeVisibilityMapForBoxTypeRecursive
} from '@lib/box/box-type'
import {
	BoxStyleMap,
} from '@lib/box/box-style'
import {
	BoxMap,
	BoxCountMap,
	BoxVisibilityMap,
	BoxAssociationsMap,
	setBoxProperties
} from '@lib/box/box'

export type BoxWeightMap = { [key: string]: number | undefined };

export interface BoxWeightMaxResult {
	boxWeightMap: BoxWeightMap;
	boxRowBoxCounts: number[];
}

export const buildBoxWeightMap = (sortedBoxKeys: string[],
	boxes: BoxMap | undefined,
	boxTypes: BoxTypeMap | undefined,
	boxTypeVisibilityMap: BoxTypeVisibilityMap | undefined,
	boxVisibilityMap: BoxVisibilityMap | undefined,
	boxStyles: BoxStyleMap | undefined,
	boxParentKey: string | undefined,
	boxParentTypeKey: string,
	boxParentProperties: Properties | undefined,
	boxParentAttributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
  	boxParentAttributes: AttributeMap | undefined,
  	boxAssociationsMap: BoxAssociationsMap | undefined,
	childLayout: ChildLayout,
	maximumGridColumns: number,
	currentDragSourceBoxKey: string,
  	currentlyHighlightedDropTargetBoxKey: string,
	canHighlightAssociations: boolean,
	illustrationFlattenedBoxMap: BoxMap | undefined): BoxWeightMaxResult => {
		// The box weight map
	const boxWeightMap: BoxWeightMap = {}
	const boxRowBoxCounts: number[] = [];

	setAttributeTypeVisibilityCacheEnabled(true);

	// Do we have any boxes?
	if ((sortedBoxKeys.length > 0) && (boxes)) {
		// The maximum number of columns in a row
		const maximumRowColumns = (childLayout === ChildLayout.GRID)
			? maximumGridColumns
			: sortedBoxKeys.length;

		// console.log(`maximumRowColumns=${maximumRowColumns}`)

		// The index of the current box being weighted
		let boxKeyIndex = 0;

		// Loop through all the boxes we're dealing with
		while(boxKeyIndex < sortedBoxKeys.length) {
			// console.log(`boxKeyIndex=${boxKeyIndex}`)

			// The current index of a visible box
			let visibleBoxIndex = 0;

			// The total static weight
			let staticBoxTotalWeight = 0;

			// The dynamic box counts
			const dynamicBoxCountMap: BoxCountMap = {}

			// The keys of the boxes in the row
			const rowBoxKeys = []

			// Remain on the current row while the we haven't used up all the columns
			while((visibleBoxIndex < maximumRowColumns) && (boxKeyIndex < sortedBoxKeys.length)) {
				const boxKey = sortedBoxKeys[boxKeyIndex];

				// Get the box
				const weightBox = boxes[boxKey];

				// console.log(`Row ${rowIndex}, Box ${boxKeyIndex} - ${weightBox.name}`)

				// Get the actual box type key
				const boxTypeKey = (weightBox.boxType) ? weightBox.boxType : '';

				// Get the box type visibility
				const boxTypeVisibility = (boxTypeVisibilityMap)
					? boxTypeVisibilityMap[boxTypeKey]
					: undefined;

				// console.log('boxTypeKey')
				// console.log(boxTypeKey)
				// console.log('boxTypeVisibility')
				// console.log(boxTypeVisibility)

				// If the boxes are not visible and not in the layout, don't include them
				if ((boxTypeVisibility)
					&& (!boxTypeVisibility.areBoxesVisible)
					&& (!boxTypeVisibility.areBoxesInLayout)) {
					// Move on to the next box
					boxKeyIndex += 1;
					continue;
				}

				// Get the box visibility
				const boxVisibility = (boxVisibilityMap)
					? boxVisibilityMap[boxKey]
					: undefined;

				// If the box is not visible and not in the layout, don't render it
				if ((boxVisibility)
					&& (!boxVisibility.isVisible)
					&& (!boxVisibility.isInLayout)) {
					// Move on to the next box
					boxKeyIndex += 1;
					continue;
				}

				// console.log(`\t\tbox=${weightBox.name}`);

				// TODO: We need to come up with a better way to get properties like
				// the weight, which are required before we render a box

				// Get the box attribute types
				const boxAttributeTypes = getBoxTypeAttributeTypeCacheForType(weightBox.boxType);

				// Get the attribute type visibilities
				const attributeTypeVisibilityMap = getAttributeTypeVisibilityMapForBoxTypeRecursive(boxTypes,
					weightBox.boxType,
					boxTypeVisibilityMap);

				// The box properties
				const boxProperties = createBoxDefaultProperties();

				// Set the box properties
				setBoxProperties(boxProperties,
					weightBox.boxType,
					boxTypes,
					boxTypeVisibilityMap,
					boxParentKey,
					boxParentTypeKey,
					weightBox.defaultProperties,
					weightBox.inheritParentProperties,
					boxParentProperties,
					weightBox.attributes,
					weightBox.inheritParentAttributes,
					boxParentAttributes,
					boxStyles,
					boxAttributeTypes,
					boxParentAttributeTypes,
					attributeTypeVisibilityMap,
					boxKey,
					'',
					'',
					{},
					{},
					currentlyHighlightedDropTargetBoxKey,
					canHighlightAssociations,
					boxAssociationsMap,
					illustrationFlattenedBoxMap);

				// Get the box weight. If the box is currently being dragged, give it a
				// zero weight.
				const boxWeight = (boxProperties.layoutWeight && boxKey !== currentDragSourceBoxKey)
					? boxProperties.layoutWeight
					: 0;

				// console.log(`\t\t\tboxWeight=${boxWeight}`);

				// The actual box weight
				let actualBoxWeight: number | undefined = undefined;

				// Does the box have a non-numeric weight?
				if ((boxWeight === undefined)
					|| (boxWeight === '')
					|| (boxWeight === 'default')
					|| (boxWeight === 'children')) {
					// Only add the box if there's space in the row
					// The dynamic box count (default to 1)
					let dynamicBoxCount = 1;

					// Does the box weight take the children into account?
					if (boxWeight === 'children') {
						// Get the number of immediate children the box has
						const boxChildCount = (weightBox.children)
							? Object.keys(weightBox.children).length
							: 0;

						// console.log(`\t\t\tboxChildCount=${boxChildCount}`);

						// Include the immediate children in the box count
						dynamicBoxCount += boxChildCount;
					}

					// console.log(`\t\t\tdynamicBoxCount=${dynamicBoxCount}`);

					// Update the dynamic box counts
					dynamicBoxCountMap[boxKey] = dynamicBoxCount;

					// Add it to the box weight map
					boxWeightMap[boxKey] = undefined;

					// We have one more key in the row
					rowBoxKeys.push(boxKey);

					// Move on to the next box
					boxKeyIndex += 1;
					visibleBoxIndex += 1;
				} else {
					// Is the box weight a number? If it's not, use a default weight
					let boxWeightNumber = Number(boxWeight);
					if (isNaN(boxWeightNumber)) {
						boxWeightNumber = 100 / maximumRowColumns;
					}

					if (boxWeightNumber < 0) boxWeightNumber = 0;
					if (boxWeightNumber > 100) boxWeightNumber = 100;

					// It's a static box, so include it in the static weight
					staticBoxTotalWeight = staticBoxTotalWeight + boxWeightNumber;

					// Store the actual box weight
					actualBoxWeight = boxWeightNumber;

					// Add it to the box weight map
					boxWeightMap[boxKey] = actualBoxWeight;

					// We have one more key in the row
					rowBoxKeys.push(boxKey);

					// Move on to the next box
					boxKeyIndex += 1;
					visibleBoxIndex += 1;
				}
			};

			// Get the total number of dynamic boxes
			let dynamicBoxCount = Object
				.keys(dynamicBoxCountMap)
				.reduce((reducedDynamicBoxCount, boxKey) => {
					reducedDynamicBoxCount += dynamicBoxCountMap[boxKey];
					return reducedDynamicBoxCount;
				}, 0)

			// Get the dynamic box total weight
			const dynamicBoxTotalWeight = 100 - staticBoxTotalWeight;

			// Get the minimum weight of a dynamic box
			const dynamicBoxMinimumWeight = (dynamicBoxCount > 0)
				? Math.abs(dynamicBoxTotalWeight / dynamicBoxCount)
				: 0;

			// // Set the dynamic box weights
			// let rowTotalBoxWeight = 0;
			// for (let i=0; i < rowBoxKeys.length; i += 1) {
			// 	const boxKey = rowBoxKeys[i];

			// 	// Get the box weight
			// 	const boxWeight = boxWeightMap[boxKey];

			// 	// If we don't have a box weight, use the dynamic box weight
			// 	if (boxWeight === undefined) {
			// 		// Get the dynamic box count for this box
			// 		const boxDynamicBoxCount = dynamicBoxCountMap[boxKey];

			// 		// Calculate the actual dynamic box weight
			// 		const actualDynamicBoxWeight = (boxDynamicBoxCount * dynamicBoxMinimumWeight);

			// 		// Store it in the box weight map
			// 		boxWeightMap[boxKey] = actualDynamicBoxWeight;

			// 		rowTotalBoxWeight += actualDynamicBoxWeight;
			// 	} else {
			// 		rowTotalBoxWeight += boxWeight;
			// 	}
			// }

			// // If the row is too "heavy", scale the weights
			// if (rowTotalBoxWeight > 100) {
			// 	const weightScale = 100 / rowTotalBoxWeight;

			// 	for (let j=0; j < rowBoxKeys.length; j += 1) {
			// 		const boxKey = rowBoxKeys[j];

			// 		// Get the box weight
			// 		const boxWeight = boxWeightMap[boxKey];
			// 		if (boxWeight !== undefined) {
			// 			// Calculate the scaled box weight
			// 			const scaledBoxWeight = (boxWeight * weightScale);

			// 			// Store it in the box weight map
			// 			boxWeightMap[boxKey] = scaledBoxWeight;
			// 		}
			// 	}
			// }

			// Set the dynamic box weights
			let rowTotalBoxWeight = 0;
			rowBoxKeys
				.forEach((boxKey) => {
					// Get the box weight
					const boxWeight = boxWeightMap[boxKey];

					// If we don't have a box weight, use the dynamic box weight
					if (boxWeight === undefined) {
						// Get the dynamic box count for this box
						const boxDynamicBoxCount = dynamicBoxCountMap[boxKey];

						// Calculate the actual dynamic box weight
						const actualDynamicBoxWeight = (boxDynamicBoxCount * dynamicBoxMinimumWeight);

						// Store it in the box weight map
						boxWeightMap[boxKey] = actualDynamicBoxWeight;

						rowTotalBoxWeight += actualDynamicBoxWeight;
					} else {
						rowTotalBoxWeight += boxWeight;
					}
				});

			// If the row is too "heavy", scale the weights
			if (rowTotalBoxWeight > 100) {
				const weightScale = 100 / rowTotalBoxWeight;

				rowBoxKeys
				.forEach((boxKey) => {
					// Get the box weight
					const boxWeight = boxWeightMap[boxKey];
					if (boxWeight !== undefined) {
						// Calculate the scaled box weight
						const scaledBoxWeight = (boxWeight * weightScale);

						// Store it in the box weight map
						boxWeightMap[boxKey] = scaledBoxWeight;
					}
				});
			}

			// rowBoxKeys.forEach((key: string, keyIndex: number) => {
			// 	console.log(`row ${boxRowBoxCounts.length}, col ${keyIndex}, ${key} : ${boxWeightMap[key]}`)
			// });
			// console.log('')

			boxRowBoxCounts.push(rowBoxKeys.length);
		}

		// console.log(`\tstaticBoxTotalWeight=${staticBoxTotalWeight}`);
		// console.log(`\tdynamicBoxTotalWeight=${dynamicBoxTotalWeight}`);
		// console.log(`\tdynamicBoxCount=${dynamicBoxCount}`);
		// console.log(`\tynamicBoxMinimumWeight=${dynamicBoxMinimumWeight}`);
		// console.log({boxWeightMap});
		// console.log(dynamicBoxCountMap);
		// console.log({boxRowBoxCounts});
	}

	setAttributeTypeVisibilityCacheEnabled(false);

	return {
		boxWeightMap,
		boxRowBoxCounts,
	};
}