import React, { ReactNode, useEffect, useState } from "react";

import { Modal, Input, InputNumber, Tree, Table, Button, Divider, Checkbox } from "antd";
import * as lensesLib from "@lib/lenses/lenses";
import * as boxTypeLib from "@lib/box/box-type";
import * as boxLib from "@lib/box/box";
import * as attributeTypeLib from "@lib/box/attribute-type";
import * as attributeLib from "@lib/box/attribute";
import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import LabelledElement from "@components/molecules/LabelledElement";
import TextArea from "antd/lib/input/TextArea";
import { ColumnProps } from "antd/lib/table";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import PowerLensQueryTable from "@components/molecules/PowerLensQueryTable";
import PowerLensParameterTable from "@components/molecules/PowerLensParameterTable";
import AddEditQueryDialog from "./AddEditQueryDialog";
import AddEditParameterDialog from "./AddEditParameterDialog";
import AddEditRestrictValuePowerLensQueryDialog from "./AddEditRestrictValuePowerLensQueryDialog";
import AddBoxTypeVisibilityPowerLensQueryDialog from "./AddBoxTypeVisibilityPowerLensQueryDialog";
const confirm = Modal.confirm;
export interface AddEditPowerLensDialogProps {
	onOKButtonClick: (powerLens: lensesLib.Lens) => void;
	onCancelButtonClick: () => void;
	isAdding: boolean;
	isVisible: boolean;
	powerLens: lensesLib.Lens;
	boxTypes: boxTypeLib.BoxTypeMap;
	boxes: boxLib.BoxMap;
	attributes: attributeLib.AttributeMap;
}

const AddEditPowerLensDialog: React.FC<AddEditPowerLensDialogProps> = (props) => {
	// Get the title
	const title = props.isAdding ? "Add Power Lens" : "Edit Power Lens";

	// Configure the state
	const [name, setName] = useState("");
	const [longName, setLongName] = useState<string | undefined>("");
	const [description, setDescription] = useState("");
	const [order, setOrder] = useState(0);
	const [queries, setQueries] = useState<lensesLib.Query[]>([]);
	const [parameters, setParameters] = useState<lensesLib.ParameterMap>({})

	// Query Dialog Variables
	const [addEditQueryDialogIsVisibile, setAddEditQueryDialogIsVisibile] = useState(false);
	const [addEditQueryDialogIsAdding, setAddEditQueryDialogIsAdding] = useState(true);
	const [addEditQuery, setAddEditQuery] = useState<lensesLib.Query | null>(null);
	const [addEditQueryIndex, setAddEditQueryIndex] = useState<number | null>(null);

	const createNewQuery = (name: string, type: lensesLib.QueryType): lensesLib.Query => {
		return {
			name: name,
			type: type,
			applyBoxTypeVisibility: false,
			applyBoxVisibility: false,
			attributeExpression: undefined,
			attributeTypeExpressionOperator: lensesLib.BooleanOperator.AND,
			attributeTypeExpressions: {},
			boxExpressionOperator: lensesLib.BooleanOperator.AND,
			boxExpressions: {},
			boxIsInLayout: false,
			boxIsVisible: false,
			boxTypeAreBoxesInLayout: false,
			boxTypeAreBoxesVisible: false,
			boxTypeAttributeTypeVisibilityMap: {},
			boxTypeExpressionOperator: lensesLib.BooleanOperator.AND,
			boxTypeExpressions: {},
			boxTypeIsVisible: false,
			boxTypeMixinBoxTypeVisibilityMap: {},
			operator: lensesLib.BooleanOperator.AND
		};
	};

	// // Query Dialog Variables
	// const [addEditParameterDialogIsVisibile, setAddEditParameterDialogIsVisibile] = useState(false);
	// const [addEditParameterDialogIsAdding, setAddEditParameterDialogIsAdding] = useState(true);
	// const [addEditParameter, setAddEditParameter] = useState<{ key: string, value: string } | null>(null);
	// const [addEditParameterKey, setAddEditParameterKey] = useState<string | null>(null);


	// Restrict Value Power Lens Dialog Variables
	const [addRestrictValuePowerLensQueryDialogIsVisible, setAddRestrictValuePowerLensQueryDialogIsVisible] = useState(false);

	// Add Box Type Visibility Power Lens Query Dialog Variables
	const [addBoxTypeVisibilityPowerLensQueryDialogIsVisible, setAddBoxTypeVisibilityPowerLensQueryDialogIsVisible] = useState(false);

	const flattenedBoxMap = boxLib.getFlattenedBoxMap(props.boxes, {});

	// const createNewParameter = (): { key: string, value: string } => {
	// 	return {
	// 		key: "",
	// 		value: ""
	// 	};
	// };

	useEffect(() => {
		const copiedObject: lensesLib.Lens = JSON.parse(JSON.stringify(props.powerLens));

		setName(copiedObject.name);
		setLongName(copiedObject.longName);
		setDescription(copiedObject.description !== undefined ? copiedObject.description : "");
		setOrder(copiedObject.order);
		setQueries(copiedObject.queries !== undefined ? copiedObject.queries : []);
		setParameters(copiedObject.parameters !== undefined ? copiedObject.parameters : {});
	}, [props.powerLens])


	const handleOKButtonClick = () => {

		const powerLens: lensesLib.Lens = { name, longName, description, order, parameters, queries }

		// Get a list of all the selected box types and then get a list of all the attributes for those box types
		// We can then add the box type and delete any attributes that aren't in the resulting list.

		props.onOKButtonClick(powerLens);
	};

	const handleEditQueryClick = (index: number) => {
		const editedQuery = queries[index];

		if (editedQuery) {
			setAddEditQueryIndex(index);
			setAddEditQuery(editedQuery);
			setAddEditQueryDialogIsAdding(false);
			setQueryFormVisibility(editedQuery.type ? editedQuery.type : lensesLib.QueryType.DEFAULT, true);
		}
	};

	const handleDeleteQuery = (index: number) => {
		const newQueries = JSON.parse(JSON.stringify(queries));
		newQueries.splice(index, 1);
		setQueries(newQueries);
	};


	const setQueryFormVisibility = (queryType: lensesLib.QueryType, visibility: boolean) => {

		// Open the right form based on the query type
		switch (queryType) {
			case lensesLib.QueryType.ATTRIBUTE:
				setAddRestrictValuePowerLensQueryDialogIsVisible(visibility);
				break;
			case lensesLib.QueryType.BOX_TYPE:
				setAddBoxTypeVisibilityPowerLensQueryDialogIsVisible(visibility);
				break;
			default:
				setAddEditQueryDialogIsVisibile(visibility);
				break;
		}

	};

	const handleAddQueryClick = (queryType: lensesLib.QueryType) => {
		setAddEditQueryIndex(null);
		setAddEditQuery(createNewQuery(name, queryType));
		setAddEditQueryDialogIsAdding(true);

		setQueryFormVisibility(queryType, true);
	};



	// const handleEditParameterClick = (key: string) => {
	// 	setAddEditParameter({ key: key, value: parameters[key].toString() });
	// 	setAddEditParameterKey(key);
	// 	setAddEditParameterDialogIsAdding(false);
	// 	setAddEditParameterDialogIsVisibile(true);
	// };

	// const handleDeleteParameter = (key: string) => {
	// 	const newParameters = JSON.parse(JSON.stringify(parameters));
	// 	delete newParameters[key];
	// 	setParameters(newParameters);
	// };

	// const handleAddParameterClick = () => {
	// 	setAddEditParameter(createNewParameter());
	// 	setAddEditParameterDialogIsAdding(true);
	// 	setAddEditParameterDialogIsVisibile(true);
	// };

	const handleAddEditQueryOkClick = (query: lensesLib.Query) => {
		const newQueries = JSON.parse(JSON.stringify(queries));

		// If We're adding, just add it to the list of queries
		if (addEditQueryDialogIsAdding) {
			newQueries.push(query);
		}
		else {
			if (addEditQueryIndex !== null) {
				newQueries[addEditQueryIndex] = query;
			}
		}

		const queryType: lensesLib.QueryType = query.type ? query.type : lensesLib.QueryType.DEFAULT;

		setQueries(newQueries);
		setQueryFormVisibility(queryType, false);
		setAddEditQuery(null);
		setAddEditQueryIndex(-1);
	};

	const handleAddEditQueryCancelClick = (queryType: lensesLib.QueryType) => {
		setAddEditQuery(null);
		setQueryFormVisibility(queryType, false);
	};


	return <Modal
		title={title}
		open={props.isVisible}
		onOk={handleOKButtonClick}
		onCancel={props.onCancelButtonClick}
		zIndex={9999}
		width="75%"
	>
		<div>
			<FormHeading>
				<FormHeadingText>Power Lens Details</FormHeadingText>
			</FormHeading>
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns: "0.1fr 0.9fr",
					gap: "4px 12px",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				{flattenedBoxMap && addEditQuery !== null &&
					<AddBoxTypeVisibilityPowerLensQueryDialog
						isVisible={addBoxTypeVisibilityPowerLensQueryDialogIsVisible}
						isAdding={addEditQueryDialogIsAdding}
						query={addEditQuery}
						onOKButtonClick={handleAddEditQueryOkClick}
						onCancelButtonClick={handleAddEditQueryCancelClick}
						flattenedBoxMap={flattenedBoxMap}
						boxTypeMap={props.boxTypes}
					/>
				}
				{flattenedBoxMap && addEditQuery !== null &&
					<AddEditRestrictValuePowerLensQueryDialog
						isVisible={addRestrictValuePowerLensQueryDialogIsVisible}
						isAdding={addEditQueryDialogIsAdding}
						query={addEditQuery}
						onOKButtonClick={handleAddEditQueryOkClick}
						onCancelButtonClick={handleAddEditQueryCancelClick}
						flattenedBoxMap={flattenedBoxMap}
						boxTypeMap={props.boxTypes}
					/>
				}
				{addEditQuery !== null &&
					<AddEditQueryDialog
						isVisible={addEditQueryDialogIsVisibile}
						isAdding={addEditQueryDialogIsAdding}
						query={addEditQuery}
						boxTypes={props.boxTypes}
						boxes={props.boxes}
						attributes={props.attributes}
						parameters={parameters}
						onOKButtonClick={handleAddEditQueryOkClick}
						onCancelButtonClick={handleAddEditQueryCancelClick}
					/>
				}
				{/* {addEditParameter !== null &&
					<AddEditParameterDialog
						parameter={addEditParameter}
						isVisible={addEditParameterDialogIsVisibile}
						isAdding={addEditParameterDialogIsAdding}

						onOKButtonClick={(parameter) => {
							setAddEditParameterDialogIsVisibile(false);
						}}

						onCancelButtonClick={() => {
							setAddEditParameter(null);
							setAddEditParameterDialogIsVisibile(false);
						}}

					/>
				} */}
				<LabelledElement labelText="Name">
					<Input
						value={name}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>
						) => { setName(event.target.value) }}
					/>
				</LabelledElement>
				<LabelledElement labelText="Long Name">
					<Input
						value={longName}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>
						) => { setLongName(event.target.value) }}
					/>
				</LabelledElement>
				<LabelledElement labelText="Description">
					<TextArea
						value={description}
						onChange={(
							event: React.ChangeEvent<HTMLTextAreaElement>
						) => { setDescription(event.target.value) }}
					/>
				</LabelledElement>
				<LabelledElement labelText="Order">
					<InputNumber
						value={order}
						onChange={(value: number | null) => { if (value !== null) { setOrder(value); } }}
					/>
				</LabelledElement>

			</div>
		</div>

		<FormHeading>
			<FormHeadingText>Queries</FormHeadingText>
			<div>
				<Button
					icon={<PlusOutlined />}
					type="primary"
					style={{
						paddingRight: "12px",
						marginRight: "12px",
					}}
					onClick={() => handleAddQueryClick(lensesLib.QueryType.BOX_TYPE)}
				>
					Add Box Type Query
				</Button>

				<Button
					icon={<PlusOutlined />}
					type="primary"
					style={{
						paddingRight: "12px",
						marginRight: "12px",
					}}
					onClick={() => handleAddQueryClick(lensesLib.QueryType.ATTRIBUTE)}
				>
					Add Attribute Query
				</Button>
				{/* <Button
					icon={<PlusOutlined />}
					type="primary"
					style={{
						paddingRight: "12px",
						marginRight: "12px",
					}}
					onClick={() => handleAddQueryClick(lensesLib.QueryType.DEFAULT)}
				>
					Add
				</Button> */}
			</div>
		</FormHeading>
		<PowerLensQueryTable canDelete={true} canEdit={true}
			onDelete={handleDeleteQuery}
			onEdit={handleEditQueryClick}
			queries={queries}
		/>
		{/* <FormHeading>
			<FormHeadingText>Parameters</FormHeadingText>
			<div>
				<Button
					icon={<PlusOutlined />}
					type="primary"
					style={{
						paddingRight: "12px",
						marginRight: "12px",
					}}
					onClick={handleAddParameterClick}
				>
					Add
				</Button>
			</div>
		</FormHeading>
		<PowerLensParameterTable
			canDelete={true}
			canEdit={true}
			onDelete={(key) => {
				confirm({
					title:
						"Are you sure delete this Parameter?",
					okText: "Yes",
					okType: "danger",
					cancelText: "No",
					zIndex: 9999999,
					onOk: () => { handleDeleteParameter(key) },
					onCancel: () => { },
				});
			}}
			onEdit={handleEditParameterClick}
			parameterMap={parameters}
		/> */}
	</Modal>
}

export default AddEditPowerLensDialog;