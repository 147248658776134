import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import { Button, Checkbox, Input, InputNumber, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import * as attributeTypeLib from '@lib/box/attribute-type';
import * as renderFunctionLib from '@lib/box/render-function';
import AddEditRenderFunctionDialog from "./add-edit-render-function-dialog/AddEditRenderFunctionDialog";
import RenderFunctionTable from "./RenderFunctionTable";
import { PlusOutlined } from "@ant-design/icons";
import LabelledElement from "@components/molecules/LabelledElement";
import * as valueTypeLib from "@lib/box/value-type";
import * as boxTypeLib from "@lib/box/box-type";
import { BoxTypeMap } from "@lib/box/box-type";
import { BoxStyleMap } from '@lib/box/box-style';

export interface AddEditAttributeTypeDialogProps {
	isVisible: boolean,
	isAdding: boolean,
	attributeType: attributeTypeLib.AttributeType,
	attributeTypeKey: string,
	boxTypeName: string,
	boxTypeKey: string,
	boxTypes: BoxTypeMap,
	onOKButtonClick: (
		attributeType: attributeTypeLib.AttributeType | undefined,
		attributeTypeKey: string
	) => void;
	onCancelButtonClick: () => void;
	valueTypes: valueTypeLib.ValueTypeMap | undefined;
	boxStyles: BoxStyleMap | undefined;
}

const initialiseRenderFunctions = (attributeType: attributeTypeLib.AttributeType): attributeTypeLib.AttributeType => {
	const copy = JSON.parse(JSON.stringify(attributeType));

	Object.keys(copy.renderFunctions).forEach((key, index) => {
		copy.renderFunctions[key].name = copy.renderFunctions[key].name !== undefined &&
			copy.renderFunctions[key].name !== null &&
			copy.renderFunctions[key].name !== '' ? copy.renderFunctions[key].name : String(index)
	});

	return copy;
};

const AddEditAttributeTypeDialog: React.FC<AddEditAttributeTypeDialogProps> = (props) => {
	const [attributeType, setAttributeType] = useState(initialiseRenderFunctions(props.attributeType));
	const [attributeTypeKey, setAttributeTypeKey] = useState(props.attributeTypeKey);

	const [renderFunction, setRenderFunction] = useState<renderFunctionLib.RenderFunctionInfo | null>(null);
	const [renderFunctionKey, setrenderFunctionKey] = useState("");


	const [addEditRenderFunctionDialogVisible, setAddEditRenderFunctionDialogVisible] = useState(false);

	const [isAddEditRenderFunctionDialogAdding, setIsAddEditRenderFunctionDialogAdding] = useState(false);

	useEffect(() => {
		setAttributeType(initialiseRenderFunctions(props.attributeType));
		setAttributeTypeKey(props.attributeTypeKey);
	}, [props.attributeType, props.attributeTypeKey])


	const handleAddEditRenderFunctionDialogOkClick = (
		newRenderFunction: renderFunctionLib.RenderFunctionInfo | undefined,
		newRenderFunctionKey: string) => {
		if (newRenderFunction) {
			const newAttributeType: attributeTypeLib.AttributeType = JSON.parse(JSON.stringify(attributeType));
			newAttributeType.renderFunctions[newRenderFunctionKey] = newRenderFunction;
			setAttributeType(newAttributeType);
		}

		setAddEditRenderFunctionDialogVisible(false);
	};

	const handleAddEditRenderFunctionDialogCancelClick = () => {
		setAddEditRenderFunctionDialogVisible(false);
	};

	const handleAddRenderFunction = () => {
		setRenderFunction(renderFunctionLib.createNewRenderFunctionInfo(0));
		setrenderFunctionKey(renderFunctionLib.getNewRenderFunctionInfoKey());
		setAddEditRenderFunctionDialogVisible(true);
		setIsAddEditRenderFunctionDialogAdding(true);
	};

	const title = `${props.isAdding ? "Add Attribute Type" : "Edit Attribute Type"} - Box Type: ${props.boxTypeName} (UUID: ${props.boxTypeKey})`;

	const gridTemplateColumns = "0.5fr 1.5fr 0.5fr 1.5fr";

	const showChoices = attributeType && attributeType.valueType === "choice";
	const showPermittedAssociationBoxTypes = attributeType && attributeType.valueType === "associations";
	const showAssociationTypes = attributeType && attributeType.valueType === "associations";
	const showAssociationsAttributeName = showAssociationTypes &&
		(attributeType.associationsType === attributeTypeLib.AssociationType.AttributeValuesLookup ||
		attributeType.associationsType === attributeTypeLib.AssociationType.UuidReverseLookup ||
		attributeType.associationsType === attributeTypeLib.AssociationType.NameReverseLookup ||
		attributeType.associationsType === attributeTypeLib.AssociationType.AttributeValueReverseLookup);

	// Build the association options
	const boxTypeOptions = Object.keys(props.boxTypes)
		.filter((boxKey) => !!props.boxTypes[boxKey].name)
		.sort((boxKeyA: string, boxKeyB: string) => {
			// Get the names of the boxes
			const boxNameA = props.boxTypes[boxKeyA].name;
			const boxNameB = props.boxTypes[boxKeyB].name;

			// If the order of box A is before that of box B, put box A first
			if (boxNameA < boxNameB) {
				return -1;
			}

			// If the order of box A is after that of box B, put box B first
			if (boxNameA > boxNameB) {
				return 1;
			}

			// Otherwise, don't change the order
			return 0;
		})
		.map((boxKey) => {
			// Get the box
			const optionBox = props.boxTypes[boxKey];

			return (
				<Select.Option key={boxKey} value={boxKey}>
					{optionBox.name}
				</Select.Option>
			);
		});

	const attributeNames: string[] = [];

	if (showAssociationsAttributeName) {
		// If we're restricting the permitted box types, only show attributes of
		// those types.
		// If we're doing a reverse lookup, only show the attributes of the
		// current box type.
		let attributeNameBoxTypes: string[] = []
		
		if (attributeType.associationsType === attributeTypeLib.AssociationType.AttributeValueReverseLookup) {
			attributeNameBoxTypes = [props.boxTypeKey];
		} else {
			if (attributeType.permittedAssociationBoxTypes && attributeType.permittedAssociationBoxTypes !== "") {
				attributeNameBoxTypes = attributeType.permittedAssociationBoxTypes.split(',');
			} else {
				attributeNameBoxTypes = Object.keys(props.boxTypes)
			}
		}

		attributeNameBoxTypes
			.forEach((boxTypeKey: string) => {
				const boxType = props.boxTypes[boxTypeKey];
				if (boxType) {
					const attributeTypes = boxTypeLib.getBoxTypeAttributeTypeCacheForType(boxTypeKey);						

					if (attributeTypes) {
						Object
							.keys(attributeTypes)
							.forEach((attributeTypeKey: string) => {
								const attributeType = attributeTypes[attributeTypeKey];
								if (attributeType) {
									const attributeName = attributeType.name;

									if (attributeNames.indexOf(attributeName) < 0) {
										attributeNames.push(attributeName)
									}
								}
							})
						}
					}
			})
	}

	const attributeNameOptions = attributeNames
		.sort((attributeNameA: string, attributeNameB: string) => {
			if (attributeNameA < attributeNameB) {
				return -1;
			}

			if (attributeNameA > attributeNameB) {
				return 1;
			}

			return 0;
		})
		.map((attributeName: string) => {
			return (
				<Select.Option key={attributeName} value={attributeName}>
					{attributeName}
				</Select.Option>
			);
		})

	return <Modal
		title={title}
		open={props.isVisible}
		onOk={() => { props.onOKButtonClick(attributeType, attributeTypeKey) }}
		onCancel={props.onCancelButtonClick}
		zIndex={9999}
		width="75%">

		{attributeType ?
			<>
				<FormHeading>
					<FormHeadingText>Attribute Type Details</FormHeadingText>
				</FormHeading>
				<div
					style={{
						width: "100%",
						display: "grid",
						gridTemplateColumns,
						gap: "4px 12px",
						padding: 0,
						margin: 0,
						overflowY: "visible",
					}}
				>
					<LabelledElement labelText="Name">

						<Input
							value={attributeType.name}
							style={{ gridColumnStart: 2, gridColumnEnd: 5 }}
							onChange={(event) => {
								var newAttributeType = JSON.parse(JSON.stringify(attributeType));
								newAttributeType.name = event.target.value;
								setAttributeType(newAttributeType);
							}}
						/>
					</LabelledElement>

					<LabelledElement labelText="Description">
						<Input.TextArea
							value={attributeType.description}
							style={{ gridColumnStart: 2, gridColumnEnd: 5 }}
							onChange={(event) => {
								var newAttributeType = JSON.parse(JSON.stringify(attributeType));
								newAttributeType.description = event.target.value;
								setAttributeType(newAttributeType);
							}}
						/>
					</LabelledElement>
					<LabelledElement labelText="Value Type">
						<Select
							size="large"
							placeholder="Please select"
							value={attributeType.valueType}
							onChange={(valueType) => {
								var newAttributeType = JSON.parse(JSON.stringify(attributeType));
								newAttributeType.valueType = valueType;
								setAttributeType(newAttributeType);
							}}

							getPopupContainer={(node) => {
								let popupContainer: HTMLElement | null =
									window.document.documentElement;
								if (node && node.parentElement) {
									popupContainer = node.parentElement;
								}
								return popupContainer as HTMLElement;
							}}
						>
							{
								props.valueTypes ?
									Object.keys(props.valueTypes).map((valueTypeKey) => {
										// Get the value type
										if (props.valueTypes) {
											const valueValueType = props.valueTypes[valueTypeKey];

											return (
												<Select.Option key={valueTypeKey} value={valueTypeKey}>
													{valueValueType.name}
												</Select.Option>
											)
										} else { return null; }

									}) : null
							}
						</Select>
					</LabelledElement>

					<LabelledElement labelText="Order">
						<InputNumber
							value={attributeType.order}

							onChange={(value: number | null) => {
								if (value) {
									var newAttributeType = JSON.parse(JSON.stringify(attributeType));
									newAttributeType.order = value;
									setAttributeType(newAttributeType);
								}
							}}
						/>
					</LabelledElement>

					<LabelledElement labelText="Show In Legend">
						<Checkbox
							checked={attributeType.showInLegend}

							onChange={(event) => {
								var newAttributeType = JSON.parse(JSON.stringify(attributeType));
								newAttributeType.showInLegend = event.target.checked;
								setAttributeType(newAttributeType);
							}}
						/>
					</LabelledElement>

					<LabelledElement labelText="Show In Summary">
						<Checkbox
							checked={attributeType.showInSummary}
							onChange={(event) => {
								var newAttributeType = JSON.parse(JSON.stringify(attributeType));
								newAttributeType.showInSummary = event.target.checked;
								setAttributeType(newAttributeType);
							}}
						/>
					</LabelledElement>

					<LabelledElement labelText="Default Value">
						<Input
							value={attributeType.defaultValue}
							style={{ gridColumnStart: 2, gridColumnEnd: 5 }}
							onChange={(event) => {
								var newAttributeType = JSON.parse(JSON.stringify(attributeType));
								newAttributeType.defaultValue = event.target.value;
								setAttributeType(newAttributeType);
							}}
						/>
					</LabelledElement>

					{showChoices ?
						<LabelledElement labelText="Choices">
							<Input
								value={attributeType.choices}

								onChange={(event) => {
									var newAttributeType = JSON.parse(JSON.stringify(attributeType));
									newAttributeType.choices = event.target.value;
									setAttributeType(newAttributeType);
								}}
							/>
						</LabelledElement> : null}

					{showPermittedAssociationBoxTypes ?
						<LabelledElement labelText="Permitted Association Box Types">
							<Select
								key={`input-${attributeTypeKey}`}
								mode="multiple"
								allowClear
								filterOption={(inputValue, option) => {
									const optionBox = option && option.value !== undefined && option.value !== null ? props.boxTypes[option.value] : null;

									// Note: some of the box names are numbers!
									// Convert it to a string just to be sure
									const optionBoxNameAsString = optionBox != null &&
										optionBox.name !== undefined &&
										optionBox.name !== null ? String(optionBox.name) : "";

									return optionBoxNameAsString.toLowerCase().includes(inputValue.toLowerCase());
								}}
								size="large"
								placeholder="Please select a Box Type"
								value={attributeType.permittedAssociationBoxTypes.split(',').filter((boxTypeKey) => {
									return props.boxTypes.hasOwnProperty(boxTypeKey);
								})}
								onChange={
									(value: string[]) => {
										var newAttributeType = JSON.parse(JSON.stringify(attributeType));
										newAttributeType.permittedAssociationBoxTypes = value.join(',');
										setAttributeType(newAttributeType);
									}
								}
								style={{ width: "100%" }}
								getPopupContainer={(node) => {
									let popupContainer: HTMLElement | null =
										window.document.documentElement;
									if (node && node.parentElement) {
										popupContainer = node.parentElement;
									}
									return popupContainer as HTMLElement;
								}}
							>
								{boxTypeOptions}
							</Select>
						</LabelledElement> : null}

						{showAssociationTypes ?
						<LabelledElement labelText="Association Type">
							<Select
								size="large"
								placeholder="Please select"
								value={attributeType.associationsType
									? attributeType.associationsType
									: attributeTypeLib.AssociationType.Uuids
								}
								onChange={(associationsType: string) => {
									let newAttributeType = JSON.parse(JSON.stringify(attributeType));
									newAttributeType.associationsType = associationsType;
									setAttributeType(newAttributeType);
								}}
								style={{ width: "100%" }}
								getPopupContainer={(node) => {
									let popupContainer: HTMLElement | null =
										window.document.documentElement;
									if (node && node.parentElement) {
										popupContainer = node.parentElement;
									}
									return popupContainer as HTMLElement;
								}}
							>
								<Select.Option
									key={attributeTypeLib.AssociationType.Uuids}
									value={attributeTypeLib.AssociationType.Uuids}
								>
									Boxes (UUIDs)
								</Select.Option>
								<Select.Option
									key={attributeTypeLib.AssociationType.NamesLookup}
									value={attributeTypeLib.AssociationType.NamesLookup}
								>
									Names Lookup
								</Select.Option>
								<Select.Option
									key={attributeTypeLib.AssociationType.AttributeValuesLookup}
									value={attributeTypeLib.AssociationType.AttributeValuesLookup}
								>
									Attribute Values Lookup
								</Select.Option>
								<Select.Option
									key={attributeTypeLib.AssociationType.UuidReverseLookup}
									value={attributeTypeLib.AssociationType.UuidReverseLookup}
								>
									Boxes Reverse Lookup (UUIDs)
								</Select.Option>
								<Select.Option
									key={attributeTypeLib.AssociationType.NameReverseLookup}
									value={attributeTypeLib.AssociationType.NameReverseLookup}
								>
									Name Reverse Lookup
								</Select.Option>
								<Select.Option
									key={attributeTypeLib.AssociationType.AttributeValueReverseLookup}
									value={attributeTypeLib.AssociationType.AttributeValueReverseLookup}
								>
									Attribute Value Reverse Lookup
								</Select.Option>
							</Select>
						</LabelledElement> : null}						

						{showAssociationsAttributeName ?
							<LabelledElement labelText="Associations Attribute Name">
							<Select
								showSearch
								key={`input-${attributeTypeKey}`}
								allowClear
								size="large"
								placeholder="Search to select an Attribute"
								value={attributeType.associationsAttributeName || ""}
								optionFilterProp="value"
								filterOption={true}
								onChange={
									(value: string) => {
										var newAttributeType = JSON.parse(JSON.stringify(attributeType));
										newAttributeType.associationsAttributeName = value;
										setAttributeType(newAttributeType);
									}
								}
								style={{ width: "100%" }}
								getPopupContainer={(node) => {
									let popupContainer: HTMLElement | null =
										window.document.documentElement;
									if (node && node.parentElement) {
										popupContainer = node.parentElement;
									}
									return popupContainer as HTMLElement;
								}}
							>
								{attributeNameOptions}
							</Select>
						</LabelledElement> : null}
				</div>
				<FormHeading>
					<FormHeadingText>Render Functions</FormHeadingText>
					<Button
						icon={<PlusOutlined />}
						type="primary"
						style={{
							paddingRight: "12px",
						}}
						onClick={handleAddRenderFunction}
					>
						Add
					</Button>
				</FormHeading>
				<RenderFunctionTable
					renderFunctions={attributeType.renderFunctions}
					onEdit={(key: string) => {
						if (attributeType) {
							setRenderFunction(attributeType.renderFunctions[key]);
							setrenderFunctionKey(key);
							setIsAddEditRenderFunctionDialogAdding(false);
							setAddEditRenderFunctionDialogVisible(true);
						}

					}}
					onDelete={(key: string) => {
						const updatedAttributeType = JSON.parse(JSON.stringify(attributeType));
						delete updatedAttributeType.renderFunctions[key];
						setAttributeType(updatedAttributeType);
					}}
				/>

				{renderFunction ?

					<AddEditRenderFunctionDialog
						attributeTypeKey={attributeTypeKey}
						attributeTypeName={attributeType.name}
						onOKButtonClick={handleAddEditRenderFunctionDialogOkClick}
						onCancelButtonClick={handleAddEditRenderFunctionDialogCancelClick}
						isVisible={addEditRenderFunctionDialogVisible}
						renderFunction={renderFunction}
						renderFunctionKey={renderFunctionKey}
						isAdding={isAddEditRenderFunctionDialogAdding}
						boxStyles={props.boxStyles}
						parentAttributeValueType={attributeType.valueType}
					/> : null}
			</>
			: null}
	</Modal>
}

export default AddEditAttributeTypeDialog;