import { v4 as uuid } from 'uuid';

import { isString, isPlainObject } from 'lodash';

import { expressions as exp } from '../3xpr';

import { Properties } from './box-properties'
import * as boxLib from './box';
import * as boxTypeLib from './box-type';
import * as attributeTypeLib from './attribute-type';
import * as attributeLib from './attribute';
import { AttributeTypeVisibilityMap, ValueType } from './attribute-type';


export type RenderFunction = ((boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any,
	boxKey?: string,
	boxType?: string,
	parentBoxKey?: string,
	parentBoxType?: string,
	parentBoxAttributeTypes?: attributeTypeLib.AttributeTypeMap,
	parentBoxAttributes?: attributeLib.AttributeMap,
	highlightedBoxKey?: string,
	highlightedBoxType?: string,
	highlightedBoxAttributeTypes?: attributeTypeLib.AttributeTypeMap,
	highlightedBoxAttributes?: attributeLib.AttributeMap,
	illustrationFlattenedBoxMap?: boxLib.BoxMap) => Properties);

export interface RenderFunctionType {
	name: string,
	description: string,
	renderFunction: RenderFunction,
	inputsType: string,
	toDisplayString: (value: any) => string,
	toValue: (stringValue: string) => any,
	defaultValue: any
}

export enum RenderFunctionTypeKey {
	SET_BOX_PROPERTY = 'setBoxProperty',
	SET_BOX_PROPERTY_FROM_ATTRIBUTE = 'setBoxPropertyFromAttributeRenderFunction',
	SET_BOX_PROPERTIES_FROM_EXPRESSIONS = 'setBoxPropertiesFromExpressions',
	SET_BOX_CHILD_LAYOUT_PROPERTY = 'setBoxChildLayoutProperty',
	SET_BOX_TEXT_PROPERTY = 'setBoxTextProperty',
	SET_BOX_TEXT_COLOR_PROPERTY = 'setBoxTextColorProperty',
	SET_BOX_TEXT_SIZE_IN_PIXELS_PROPERTY = 'setBoxTextSizeInPixelsProperty',
	SET_BOX_TEXT_SIZING_PROPERTY = 'setBoxTextSizingProperty',
	SET_BOX_TEXT_ALIGNMENT_PROPERTY = 'setBoxTextAlignmentProperty',
	SET_BOX_TEXT_ALIGNMENT_PADDING_IN_PIXELS_PROPERTY = 'setBoxTextAlignmentPaddingInPixelsProperty',
	SET_BOX_TEXT_IS_VERTICAL_PROPERTY = 'setBoxTextIsVerticalProperty',
	SET_BOX_FONT_FAMILY_PROPERTY = 'setBoxFontFamilyProperty',
	SET_BOX_FONT_STYLE_PROPERTY = 'setBoxFontStyleProperty',
	SET_BOX_FONT_WEIGHT_PROPERTY = 'setBoxFontWeightProperty',
	SET_BOX_BACKGROUND_COLOR_PROPERTY = 'setBoxBackgroundColorProperty',
	SET_BOX_BACKGROUND_IMAGE_PROPERTY = 'setBoxBackgroundImageProperty',
	SET_BOX_BACKGROUND_IMAGE_POSITION_PROPERTY = 'setBoxBackgroundImagePositionProperty',
	SET_BOX_BACKGROUND_IMAGE_SIZE_PROPERTY = 'setBoxBackgroundImageSizeProperty',
	SET_BOX_BACKGROUND_IMAGE_REPEAT_PROPERTY = 'setBoxBackgroundImageRepeatProperty',
	SET_BOX_BORDER_COLOR_PROPERTY = 'setBoxBorderColorProperty',
	SET_BOX_BORDER_RADIUS_PROPERTY = 'setBoxBorderRadiusProperty',
	SET_BOX_BORDER_SIZE_IN_PIXELS_PROPERTY = 'setBoxBorderSizeInPixelsProperty',
	SET_BOX_BORDER_STYLE_PROPERTY = 'setBoxBorderStyleProperty',
	SET_BOX_BADGE_ATTRIBUTE_TYPE_PROPERTY = 'setBoxBadgeAttributeTypeProperty',
	SET_BOX_BADGE_BACKGROUND_COLOR_PROPERTY = 'setBoxBadgeBackgroundColorProperty',
	SET_BOX_BADGE_TEXT_COLOR_PROPERTY = 'setBoxBadgeTextColorProperty',
	SET_BOX_BADGE_TEXT_PROPERTY = 'setBoxBadgeTextProperty',
	SET_BOX_BADGE_1_PROPERTY = 'setBoxBadge1Property',
	SET_BOX_BADGE_2_PROPERTY = 'setBoxBadge2Property',
	SET_BOX_BADGE_3_PROPERTY = 'setBoxBadge3Property',
	SET_BOX_BADGE_4_PROPERTY = 'setBoxBadge4Property',
	SET_BOX_BADGE_5_PROPERTY = 'setBoxBadge5Property',
	SET_BOX_BADGE_6_PROPERTY = 'setBoxBadge6Property',
	SET_BOX_BADGE_7_PROPERTY = 'setBoxBadge7Property',
	SET_BOX_BADGE_8_PROPERTY = 'setBoxBadge8Property',
	SET_BOX_BADGE_9_PROPERTY = 'setBoxBadge9Property',
	SET_BOX_BADGE_10_PROPERTY = 'setBoxBadge10Property',
	SET_BOX_BADGE_11_PROPERTY = 'setBoxBadge11Property',
	SET_BOX_BADGE_12_PROPERTY = 'setBoxBadge12Property',
	SET_BOX_BADGE_13_PROPERTY = 'setBoxBadge13Property',
	SET_BOX_BADGE_14_PROPERTY = 'setBoxBadge14Property',
	SET_BOX_BADGE_15_PROPERTY = 'setBoxBadge15Property',
	SET_BOX_BADGE_16_PROPERTY = 'setBoxBadge16Property',
	SET_BOX_BADGE_17_PROPERTY = 'setBoxBadge17Property',
	SET_BOX_BADGE_18_PROPERTY = 'setBoxBadge18Property',
	SET_BOX_BADGE_19_PROPERTY = 'setBoxBadge19Property',
	SET_BOX_BADGE_20_PROPERTY = 'setBoxBadge20Property',
	SET_BOX_BADGE_21_PROPERTY = 'setBoxBadge21Property',
	SET_BOX_BADGE_22_PROPERTY = 'setBoxBadge22Property',
	SET_BOX_BADGE_23_PROPERTY = 'setBoxBadge23Property',
	SET_BOX_BADGE_24_PROPERTY = 'setBoxBadge24Property',
	SET_BOX_BADGE_25_PROPERTY = 'setBoxBadge25Property',
	SET_BOX_BADGE_26_PROPERTY = 'setBoxBadge26Property',
	SET_BOX_BADGE_27_PROPERTY = 'setBoxBadge27Property',
	SET_BOX_BADGE_28_PROPERTY = 'setBoxBadge28Property',
	SET_BOX_BADGE_29_PROPERTY = 'setBoxBadge29Property',
	SET_BOX_BADGE_30_PROPERTY = 'setBoxBadge30Property',
	SET_BOX_BADGE_31_PROPERTY = 'setBoxBadge31Property',
	SET_BOX_BADGE_32_PROPERTY = 'setBoxBadge32Property',

	SET_BOX_LAYOUT_WEIGHT_PROPERTY = 'setBoxLayoutWeightProperty',
	SET_BOX_STYLES_PROPERTY = 'setBoxStylesProperty',
	SET_BOX_MAXIMUM_GRID_COLUMNS_PROPERTY = 'setBoxMaximumGridColumnsProperty',

	MAP_CHOICE_ATTRIBUTE_TO_BOX_PROPERTY = 'mapChoiceAttributeToBoxProperty',
}

export enum RenderFunctionInputsType {
	// TODO: Add other types here
	STRING = 'string',
	NUMBER = 'number',
	BOOLEAN = 'boolean',
	COLOR = 'color',
	OBJECT = 'object',
	STYLE = 'style'
}

export enum RenderFunctionMode {
	ASSOCIATION = "association",
	NORMAL = "normal"
}

export const PrettyRenderFunctionModeList = [
	{id: RenderFunctionMode.ASSOCIATION, name: "Association"},
	{id: RenderFunctionMode.NORMAL, name: "Normal"},
];

export type RenderFunctionTypeMap = { [key: string]: RenderFunctionType }

export type RenderFunctionVisibilityMap = { [key: string]:  RenderFunctionVisibility };

export interface RenderFunctionVisibility {
	isVisible: boolean;
}

export interface RenderFunctionInfo {
	name?: string,
	order: number,
	type: string,
	inputs: any,
	mode: RenderFunctionMode | undefined
}

export type RenderFunctionInfoMap = { [key: string]: RenderFunctionInfo }

// Used by the `setBoxPropertyFromAttribute` render function.
export interface SetBoxPropertyFromAttributeRenderFunctionInputs {
	// The key of the property to set on the box.
	boxPropertyKey: string;

	// Deprecated field to choose where to lookup an attribute value from
	// (default is the value of the attribute this render function is executing
	// on).
	alternateAttributeTypeSource?: string;
}

// Specifies where an expression variable should be sourced from.
export type ExpressionVariableSourceType =
	// The value of attribute the render function is executing on.
	| 'attributeValue'
	// An attribute on the current box the render function is executing on.
	| 'currentBoxAttribute'
	// An attribute on the parent of the current box.
	| 'parentBoxAttribute'
	// An attribute on the highlighted box.
	| 'highlightedBoxAttribute'
	// An attribute on another box in the illustration.
	| 'globalBoxAttribute'
	// The children of another box based on the ID
	| 'globalBoxChildrenById'
	// An expression.
	| 'expression';

// Specifies how to lookup an attribute on the source box.
export type AttributeLookupType =
	// Search by the UUID of the attribute type.
	'byAttributeTypeUuid' |
	// Search by the name of the attribute type.
	'byAttributeTypeName' |
	// Choose how to lookup the attribute based on the type of the box that is
	// the source of the attribute.
	'byBoxTypeAttributeLookup';

// Specifies how to lookup an attribute using a box type map (same as the
// general lookup types but with the box type map option removed to prevent
// recursion).
export type BoxTypeMapAttributeLookupType =
	// Search by the UUID of the attribute type.	
	'byAttributeTypeUuid' |
	// Search by the name of the attribute type.
	'byAttributeTypeName';

// Specifies how to lookup an attribute based on the t box type
export interface BoxTypeMapAttributeLookup {
	// The attribute lookup type.
	attributeLookupType: BoxTypeMapAttributeLookupType;

	// The type name to use when looking up the attribyte (only valid for
	// `byAttributeTypeName`).
	attributeTypeName?: string;

	// The type UUID to use when looking up the attribute (only valid for
	// `byAttributeTypeUuid`).
	attributeTypeUuid?: string;
}

// Maps a box type to an attribute lookup method.
export type BoxTypeToAttributeLookupMap = Record<string, BoxTypeMapAttributeLookup>

// Describes an expression variable.
export interface ExpressionVariable {
	// The name of the variable.
	name: string;

	// The source of the expression variable.
	sourceType: ExpressionVariableSourceType;

	// How an attribute should be looked up (only valid for
	// `currentBoxAttribute`, `parentBoxAttribute`, `highlightedBoxAttribute`,
	/// and `globalBoxAttribute` source types).
	attributeLookupType: AttributeLookupType;

	// The type name to use when looking up the attribute (only valid for
	// `byAttributeTypeName`).
	attributeTypeName?: string;

	// The type UUID to use when looking up the attribute (only valid for
	// `byAttributeTypeUuid`).
	attributeTypeUuid?: string;

	// The box type map to use when looking up an attribute (only valid for
	// `byBoxTypeMap`).
	boxTypeAttributeLookups?: BoxTypeToAttributeLookupMap;

	// The UUID of the box to use as the source of the attribute (only valid
	// for `globalBoxAttribute` source type).
	globalBoxUuid?: string;

	// The expression to use to define the variable.
	expression?: string;
}

// Describes the inputs of the `setBoxPropertiesFromExpressions` render
// function.
export interface SetBoxPropertiesFromExpressionsRenderFunctionInputs {
	// Associates the key of a box property with the expression used to set it.
	boxPropertyExpressions: Record<string, string>;

	// The variables that will be available in the expressions.
	variables?: ExpressionVariable[];

	// The constants that will be available in the expressions.
	constants?: Record<string, attributeLib.AttributeValue>;
}

export const getModeValueOrDefault = (mode: RenderFunctionMode | undefined,  parentAttributeValueType: string) => {
	return mode 
		? mode 
		: parentAttributeValueType === ValueType.Associations.toString() 
			? RenderFunctionMode.ASSOCIATION 
			: RenderFunctionMode.NORMAL
};

export const getRenderFunctionVisibilityOrDefault = (
	attributeTypeVisibilityMap: AttributeTypeVisibilityMap | undefined,
	attributeTypeKey: string,
	renderFunctionTypeKey: string 
	) => {
		if (!attributeTypeVisibilityMap) {
			return false;
		}

		const attributeTypeVisibility = attributeTypeVisibilityMap[attributeTypeKey];

		const attributeTypeVisibilityBoolean = attributeTypeLib.getAttributeVisibility(attributeTypeVisibility)

		// If we've got a render function in the visibility map then return it's "isVisibile" value, 
		// otherwise return whatever the attribute is
		const renderFunctionVisibility = 
			typeof attributeTypeVisibility === 'object' && 
			!Array.isArray(attributeTypeVisibilityMap[attributeTypeKey]) && 
			attributeTypeVisibility!== null && 
			attributeTypeVisibility !== undefined && 
			Object.prototype.hasOwnProperty.call(attributeTypeVisibility, "renderFunctionVisibilityMap") && 
			attributeTypeVisibility.renderFunctionVisibilityMap && 
			attributeTypeVisibility.renderFunctionVisibilityMap[renderFunctionTypeKey] ? 
			attributeTypeVisibility.renderFunctionVisibilityMap[renderFunctionTypeKey].isVisible : attributeTypeVisibilityBoolean;

		// console.log(`Visibility calc - attributeTypeKey: ${attributeTypeKey}, renderFunctionTypeKey: ${renderFunctionTypeKey}, attributeTypeVisibilityBoolean: ${attributeTypeVisibilityBoolean}, renderFunctionVisibility: ${
		// 	typeof attributeTypeVisibility === 'object' && 
		// 	!Array.isArray(attributeTypeVisibilityMap[attributeTypeKey]) && 
		// 	attributeTypeVisibility!== null && 
		// 	attributeTypeVisibility !== undefined && 
		// 	Object.prototype.hasOwnProperty.call(attributeTypeVisibility, "renderFunctionVisibilityMap") && 
		// 	attributeTypeVisibility.renderFunctionVisibilityMap && 
		// 	attributeTypeVisibility.renderFunctionVisibilityMap[renderFunctionTypeKey] ?  attributeTypeVisibility.renderFunctionVisibilityMap[renderFunctionTypeKey].isVisible : "can't find it"} `)

		// console.log(attributeTypeKey, attributeTypeVisibility);

		return renderFunctionVisibility;

};


const setBoxPropertyRenderFunction = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			inputs,
		);
	}

	return updatedBoxProperties as Properties;
}

const addAttributesToExpressionContext = (expressionContext: Record<string, Record<string, any>>,
	expressionContextKey: string,
	box?: boxLib.Box,
	boxKey?: string,
	boxTypeKey?: string,
	attributeTypes?: attributeTypeLib.AttributeTypeMap,
	attributes?: attributeLib.AttributeMap): void => {
	if (!attributeTypes || !attributes) {
		return;
	}

	// Make sure the expression context has an property for the key we'll be
	// storing the attributes under.
	if (!Object.prototype.hasOwnProperty.call(expressionContext,
		expressionContextKey)) {
		expressionContext[expressionContextKey] = {};
	}

	const expressionAttributes = expressionContext[expressionContextKey];
	if (!expressionAttributes) {
		return;
	}

	// Set the base properties of the box. Note that these aren't attributes
	if (box) {
		expressionAttributes['Name'] = box.name;
		expressionAttributes['UUID'] = boxKey;
		expressionAttributes['BoxType'] = box.boxType;
		expressionAttributes['Order'] = box.order;
	}

	// Add the attributes to the expression context under the specified key,
	// storing them under both the UUID and name.
	Object
		.keys(attributeTypes)
		.forEach((attributeTypeKey: string) => {
			const attributeType = attributeTypes[attributeTypeKey];
			if (attributeType) {
				const attributeTypeName = attributeType.name;

				if (Object.prototype.hasOwnProperty.call(attributes,
					attributeTypeKey)) {
					const attributeValue = attributes[attributeTypeKey]
					if (attributeValue !== undefined) {
						// Store the value under the attribute type UUID and name. 
						expressionAttributes[attributeTypeKey] = attributeValue;
						expressionAttributes[attributeTypeName] = attributeValue;

						// Add box info.
						// TODO: These migh collide with the actual attribute names.
						if (boxTypeKey) {
							expressionAttributes['BoxType'] = boxTypeKey;
						}
					}
				}
			}
		});
}

const getBoxChildrenAsAttributeValueArray = (children?: boxLib.BoxMap): Array<Record<string, attributeLib.AttributeValue>> => {
	if (!children) {
		return [];
	}

	const returnValue: Array<Record<string, attributeLib.AttributeValue>> = [];

	Object
		.keys(children)
		.forEach((childBoxKey: string) => {
			const childBox = children[childBoxKey];
			if (childBox) {
				const childBoxTypeKey = childBox.boxType;
				const childBoxAttributeTypes = boxTypeLib
					.getBoxTypeAttributeTypeCacheForType(childBoxTypeKey);
				if (childBoxAttributeTypes) {
					const childAttributes = childBox.attributes;
					if (childAttributes) {
						// Copy the attributes.
						const childAttributeValues: Record<string, attributeLib.AttributeValue> = JSON.parse(JSON.stringify(childAttributes));

						// Add box info.
						// TODO: These migh collide with the actual attribute names.
						childAttributeValues['Name'] = childBox.name;
						childAttributeValues['UUID'] = childBoxKey;
						childAttributeValues['BoxType'] = childBox.boxType;
						childAttributeValues['Order'] = childBox.order;

						// Add the attributes using their names as keys.
						Object
							.keys(childBoxAttributeTypes)
							.forEach((childBoxAttributeTypeKey: string) => {
								const childBoxAttributeType = childBoxAttributeTypes[childBoxAttributeTypeKey];
								const childBoxAttributeName = childBoxAttributeType.name;

								if (Object.prototype.hasOwnProperty.call(childAttributeValues,
									childBoxAttributeTypeKey)) {
									childAttributeValues[childBoxAttributeName] = childAttributeValues[childBoxAttributeTypeKey]
								}
							})

						returnValue.push(childAttributeValues);
					}
				}
			}
		});

	return returnValue;
}


const addBoxChildrenToExpressionContext = (expressionContext: Record<string, Record<string, any>>,
	expressionContextKey: string,
	children?: boxLib.BoxMap): void => {
	if (!children) {
		return;
	}

	// Make sure the expression context has an property for the key we'll be
	// storing the attributes under.
	if (!Object.prototype.hasOwnProperty.call(expressionContext,
		expressionContextKey)) {
		// Children are stored as an array.
		expressionContext[expressionContextKey] = [];
	}

	const expressionChildren = expressionContext[expressionContextKey];
	if (!expressionChildren) {
		return;
	}

	Object
		.keys(children)
		.forEach((childBoxKey: string) => {
			const childBox = children[childBoxKey];
			if (childBox) {
				const childBoxTypeKey = childBox.boxType;
				const childBoxAttributeTypes = boxTypeLib
					.getBoxTypeAttributeTypeCacheForType(childBoxTypeKey);
				if (childBoxAttributeTypes) {
					const childAttributes = childBox.attributes;
					if (childAttributes) {
						// Copy the attributes.
						const childAttributeValues: Record<string, attributeLib.AttributeValue> = JSON.parse(JSON.stringify(childAttributes));

						// Add box info.
						// TODO: These migh collide with the actual attribute names.
						childAttributeValues['Name'] = childBox.name;
						childAttributeValues['UUID'] = childBoxKey;
						childAttributeValues['BoxType'] = childBox.boxType;
						childAttributeValues['Order'] = childBox.order;

						// Add the attributes using their names as keys.
						Object
							.keys(childBoxAttributeTypes)
							.forEach((childBoxAttributeTypeKey: string) => {
								const childBoxAttributeType = childBoxAttributeTypes[childBoxAttributeTypeKey];
								const childBoxAttributeName = childBoxAttributeType.name;

								if (Object.prototype.hasOwnProperty.call(childAttributeValues,
									childBoxAttributeTypeKey)) {
									childAttributeValues[childBoxAttributeName] = childAttributeValues[childBoxAttributeTypeKey]
								}
							})

						expressionChildren.push(childAttributeValues);
					}
				}
			}
		});
}

const setBoxPropertyFromAttributeRenderFunction = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties, attributes, attribute types, and an attribute type?
	if ((boxProperties)
		&& (attributes)
		&& (attributeTypes)
		&& (attributeAttributeType)) {
		// Do we have inputs? 
		if (inputs) {
			// The actual attribute value
			let actualAttributeValue: attributeLib.AttributeValue = attributeValue;

			// Do we have an alternate attribute type value source?
			if (Object.prototype.hasOwnProperty.call(inputs, 'alternateAttributeTypeSource')) {
				// Get the attribute type key
				const attributeTypeKey = inputs.alternateAttributeTypeSource;

				// Get the box attribute type
				const boxAttributeType = attributeTypes[attributeTypeKey];
				if (boxAttributeType) {
					// The attribute value (set to the default value initially)
					actualAttributeValue = boxAttributeType.defaultValue;

					// Does the box have a value for this attribute type?
					if (Object.prototype.hasOwnProperty.call(attributes, attributeTypeKey)) {
						// Get the value
						actualAttributeValue = attributes[attributeTypeKey]
					}
				}
			}

			// Do we have an actual attribute value?
			if (actualAttributeValue !== undefined) {
				// Get the box property key
				const boxPropertyKey = inputs.boxPropertyKey;

				// Merge the inputs into the box properties
				Object.assign(updatedBoxProperties,
					boxProperties,
					{
						[boxPropertyKey]: actualAttributeValue,
					}
				);
			}
		}
	}

	return updatedBoxProperties as Properties;
}

const setBoxPropertiesFromExpressions = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any,
	boxKey?: string,
	boxType?: string,
	parentBoxKey?: string,
	parentBoxType?: string,
	parentBoxAttributeTypes?: attributeTypeLib.AttributeTypeMap,
	parentBoxAttributes?: attributeLib.AttributeMap,
	highlightedBoxKey?: string,
	highlightedBoxType?: string,
	highlightedBoxAttributeTypes?: attributeTypeLib.AttributeTypeMap,
	highlightedBoxAttributes?: attributeLib.AttributeMap,
	illustrationFlattenedBoxMap?: boxLib.BoxMap): Properties => {

	// The updated box properties
	const updatedBoxProperties = boxProperties
		? JSON.parse(JSON.stringify(boxProperties))
		: boxProperties;

	// Do we have box properties, attributes, attribute types, and an attribute type?
	if ((boxProperties)
		&& (attributes)
		&& (attributeTypes)
		&& (attributeAttributeType)) {
		// Do we have inputs? 
		if (inputs) {

			const renderFunctionInputs = inputs as SetBoxPropertiesFromExpressionsRenderFunctionInputs;

			const {
				boxPropertyExpressions,
				variables,
				constants,
			} = renderFunctionInputs;

			const expressionContextVariables: Record<string, attributeLib.AttributeValue> = {};
			const expressionContextConstants: Record<string, attributeLib.AttributeValue> = constants ? constants : {};
			const expressionContext: Record<string, any> = {
				attr: attributeValue,
				variables: expressionContextVariables,
				constants: expressionContextConstants,
			}

			if (boxKey && illustrationFlattenedBoxMap) {
				// Add the attributes of the current box.
				addAttributesToExpressionContext(expressionContext,
					'current',
					illustrationFlattenedBoxMap[boxKey],
					boxKey,
					boxType,
					attributeTypes,
					attributes);
			}

			if (parentBoxKey && illustrationFlattenedBoxMap) {
				// Add the attributes of the parent box.
				addAttributesToExpressionContext(expressionContext,
					'parent',
					illustrationFlattenedBoxMap[parentBoxKey],
					parentBoxKey,
					parentBoxType,
					parentBoxAttributeTypes,
					parentBoxAttributes);
			}

			if (highlightedBoxKey && illustrationFlattenedBoxMap) {
				// Add the attributes of the highlighted box.
				addAttributesToExpressionContext(expressionContext,
					'highlighted',
					illustrationFlattenedBoxMap[highlightedBoxKey],
					highlightedBoxKey,
					highlightedBoxType,
					highlightedBoxAttributeTypes,
					highlightedBoxAttributes);
			}

			// Add the attributes of the children as an array.
			if (illustrationFlattenedBoxMap && boxKey) {
				const box = illustrationFlattenedBoxMap[boxKey];
				if (box) {
					addBoxChildrenToExpressionContext(expressionContext,
						'children',
						box.children);
				}
			}

			// Evaluate all the variables in the order they're specified.
			// Each successive variable will have access to already evaluated
			// variables.
			if (variables && Array.isArray(variables)) {

				variables
					.forEach((expressionVar: ExpressionVariable) => {
						const {
							name,
							sourceType,
							attributeLookupType,
							attributeTypeName,
							attributeTypeUuid,
							boxTypeAttributeLookups,
							globalBoxUuid,
							expression,
						} = expressionVar;

						// If the variable is being sourced from the attribute value,
						// record it and skip to the next variable. 
						if (sourceType === 'attributeValue') {
							expressionContext.variables[name] = attributeValue;
							return;
						}

						// If the variable is being sourced from an expresssion,
						// evaluate it. The expression being evaluated will have
						// everything in the current state of the evaluation
						// context available to it (i.e. the usual box
						// attributes plus all variables evaluated so far). 
						if (sourceType === 'expression') {
							if (expression) {
								try {
									const expressionResult = exp.eval(expression,
										expressionContext) as attributeLib.AttributeValue;

									// Do we have an actual attribute value?
									if (!!expressionResult) {
										// console.log(`variable ${expression} = ${expressionResult}`);

										expressionContext.variables[name] = expressionResult;
									}
								} catch (e) {
									// console.log(`Failed to set expression: variable ${expression} = ${e}`);
								}
							}

							return;
						}

						if (sourceType === 'globalBoxChildrenById') {
							if (illustrationFlattenedBoxMap && globalBoxUuid) {
								const globalBox = illustrationFlattenedBoxMap[globalBoxUuid]
								if (globalBox) {
									try {
										const result = getBoxChildrenAsAttributeValueArray(globalBox.children);

										// Do we have an actual attribute value?
										if (!!result) {
											// console.log(result);
											// console.log(`variable ${expression} = ${expressionResult}`);

											expressionContext.variables[name] = result;
										}
									} catch (e) {
										console.log(`Failed to set globalBoxChildrenById: ${name} = ${globalBoxUuid}`);
									}
								}
							}

							return;
						}

						// We're setting the expression variable by looking up an
						// attribute, so figure out the information required to do that.
						let sourceBoxType = '';
						let sourceBoxAttributes: attributeLib.AttributeMap = {};
						let sourceBoxAttributeTypes: attributeTypeLib.AttributeTypeMap = {};

						if (sourceType === 'currentBoxAttribute') {
							if (boxType) {
								sourceBoxType = boxType;
							}
							sourceBoxAttributes = attributes;
							sourceBoxAttributeTypes = attributeTypes;
						} else if (sourceType === 'parentBoxAttribute') {
							if (parentBoxType) {
								sourceBoxType = parentBoxType;
							}
							if (parentBoxAttributes) {
								sourceBoxAttributes = parentBoxAttributes;
							}
							if (parentBoxAttributeTypes) {
								sourceBoxAttributeTypes = parentBoxAttributeTypes;
							}
						} else if (sourceType === 'highlightedBoxAttribute') {
							if (highlightedBoxType) {
								sourceBoxType = highlightedBoxType;
							}
							if (highlightedBoxAttributes) {
								sourceBoxAttributes = highlightedBoxAttributes;
							}
							if (highlightedBoxAttributeTypes) {
								sourceBoxAttributeTypes = highlightedBoxAttributeTypes;
							}
						} else if (sourceType === 'globalBoxAttribute') {
							if (illustrationFlattenedBoxMap && globalBoxUuid) {
								const globalBox = illustrationFlattenedBoxMap[globalBoxUuid]
								if (globalBox) {
									sourceBoxType = globalBox.boxType;
									if (globalBox.attributes) {
										sourceBoxAttributes = globalBox.attributes;
									}
									sourceBoxAttributeTypes = boxTypeLib
										.getBoxTypeAttributeTypeCacheForType(globalBox.boxType);
								}
							}
						} else {
							// We ran into an unknown source type, so skip.
							return;
						}

						// Get the source box attribute type key
						let lookupAttributeTypeUuid = attributeTypeUuid;
						let lookupAttributeTypeName = attributeTypeName;
						let lookupType = attributeLookupType;

						if (attributeLookupType === 'byBoxTypeAttributeLookup') {
							if (boxTypeAttributeLookups) {
								const attributeLookup = boxTypeAttributeLookups[sourceBoxType]
								if (attributeLookup) {
									if (attributeLookup.attributeLookupType === 'byAttributeTypeUuid') {
										lookupAttributeTypeUuid = attributeLookup.attributeTypeUuid;
									} else if (attributeLookup.attributeLookupType === 'byAttributeTypeName') {
										lookupAttributeTypeName = attributeLookup.attributeTypeName;
									}
								}
							}
						}

						let sourceBoxAttributeTypeKey = '';

						if (lookupType === 'byAttributeTypeUuid') {
							if (lookupAttributeTypeUuid) {
								sourceBoxAttributeTypeKey = lookupAttributeTypeUuid;
							}
						} else if (lookupType === 'byAttributeTypeName') {
							if (lookupAttributeTypeName) {
								sourceBoxAttributeTypeKey = attributeTypeLib
									.findAttributeTypeKeyForName(sourceBoxType,
										sourceBoxAttributeTypes,
										lookupAttributeTypeName)
							}
						} else {
							// We ran into an unknown source type, so skip.
							return;
						}

						// Get the source box attribute type.
						const sourceBoxAttributeType = sourceBoxAttributeTypes[sourceBoxAttributeTypeKey];
						if (sourceBoxAttributeType) {
							// The attribute value (set to the default value initially).
							let actualAttributeValue = sourceBoxAttributeType.defaultValue;

							// Does the source box have a value for this attribute type?
							if (Object.prototype.hasOwnProperty.call(sourceBoxAttributes,
								sourceBoxAttributeTypeKey)) {
								// Get the value.
								actualAttributeValue = sourceBoxAttributes[sourceBoxAttributeTypeKey]

								// Record the actual attribute value for the variable.
								expressionContext.variables[name] = actualAttributeValue;
							}
						}
					})
			}

			// Evaluate the expressions for each box property.
			if (boxPropertyExpressions) {
				Object
					.keys(boxPropertyExpressions)
					.forEach((boxPropertyKey: string) => {
						// Get the expression.
						const expression = boxPropertyExpressions[boxPropertyKey];

						try {
							const expressionResult = exp.eval(expression,
								expressionContext) as attributeLib.AttributeValue;

							// Do we have an actual attribute value?
							if (!!expressionResult) {
								// console.log(`box property ${boxPropertyKey} expr: ${expression} = ${expressionResult}`);

								// Try and parse the expression results as JSON.
								let actualExpressionResult = expressionResult
								try {
									if (isString(expressionResult)) {
										const parsedExpressionResult = JSON.parse(actualExpressionResult as string)
										if (isPlainObject(parsedExpressionResult)) {
											actualExpressionResult = parsedExpressionResult
										}
									}
								} catch (e) {
									// console.log(e);
									// console.log('Invalid badge JSON')
									// console.log(inputs)
								}

								// Update the box properties.
								Object.assign(updatedBoxProperties,
									updatedBoxProperties,
									{
										[boxPropertyKey]: actualExpressionResult,
									}
								);

								// if (Object.keys(updatedBoxProperties).length > 0) {
								// 	console.log('updatedBoxProperties update', updatedBoxProperties);
								// }
							}
						} catch (e) {
							console.log(`error with box property expr: ${expression} = ${e}`);
						}
					})
			}
		}
	}

	// if (Object.keys(updatedBoxProperties).length > 0) {
	// 	console.log('updatedBoxProperties', updatedBoxProperties);
	// }

	return updatedBoxProperties as Properties;
}

const setBoxChildLayoutProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				childLayout: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxTextProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				text: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxTextColorProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				textColor: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxTextSizeInPixelsProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				textSizeInPixels: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxTextSizingProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				textSizing: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxTextAlignmentProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				textAlignment: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxTextAlignmentPaddingInPixelsProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				textAlignmentPaddingInPixels: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxTextIsVerticalProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				textIsVertical: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxFontFamilyProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				fontFamily: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxFontStyleProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				fontStyle: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxFontWeightProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				fontWeight: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxBackgroundColorProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				backgroundColor: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxBackgroundImageProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				backgroundImage: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxBackgroundImagePositionProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				backgroundImagePosition: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxBackgroundImageSizeProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				backgroundImageSize: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxBackgroundImageRepeatProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				backgroundImageRepeat: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxBorderColorProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				borderColor: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxBorderRadiusProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				borderRadius: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxBorderSizeInPixelsProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				borderSizeInPixels: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxBorderStyleProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				borderStyle: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxBadgeAttributeTypeProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				badgeAttributeType: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxBadgeBackgroundColorProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				badgeBackgroundColor: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxBadgeTextColorProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				badgeTextColor: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxBadgeTextProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				badgeText: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxBadgeProperty = (badgePropertyKey: string,
	boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {

		// Try and parse the badge JSON
		let actualBadgePropertyValue = inputs
		try {
			actualBadgePropertyValue = JSON.parse(inputs)
		} catch (e) {
			// console.log('Invalid badge JSON')
			// console.log(inputs)
		}

		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				[badgePropertyKey]: actualBadgePropertyValue,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxBadgePropertyRenderFunc = (badgeKey: string): RenderFunction => {
	return (boxProperties: Properties | undefined,
		attributes: attributeLib.AttributeMap | undefined,
		attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
		attributeAttributeType: attributeTypeLib.AttributeType | undefined,
		attributeValue: attributeLib.AttributeValue,
		inputs: any): Properties => setBoxBadgeProperty(badgeKey,
			boxProperties,
			attributes,
			attributeTypes,
			attributeAttributeType,
			attributeValue,
			inputs)
}

const setBoxLayoutWeightProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				layoutWeight: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxStylesProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				styles: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const setBoxMaximumGridColumnsProperty = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties and an attribute type?
	if ((boxProperties) && (attributeAttributeType)) {
		// Merge the inputs into the box properties
		Object.assign(updatedBoxProperties,
			boxProperties,
			{
				maximumGridColumns: inputs,
			}
		);
	}

	return updatedBoxProperties as Properties;
}

const mapChoiceAttributeToBoxPropertyRenderFunction = (boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeAttributeType: attributeTypeLib.AttributeType | undefined,
	attributeValue: attributeLib.AttributeValue,
	inputs: any): Properties => {
	// The updated box properties
	const updatedBoxProperties = {};

	// Do we have box properties, attributes, attribute types, and an attribute type?
	if ((boxProperties)
		&& (attributes)
		&& (attributeTypes)
		&& (attributeAttributeType)) {
		// Do we have inputs?, and 
		if (inputs) {
			// The actual attribute value
			let actualAttributeValue: attributeLib.AttributeValue = attributeValue;

			// Do we have an alternate attribute type value source?
			if (Object.prototype.hasOwnProperty.call(inputs, 'alternateAttributeTypeSource')) {
				// Get the attribute type key
				const attributeTypeKey = inputs.alternateAttributeTypeSource;

				// Get the box attribute type
				const boxAttributeType = attributeTypes[attributeTypeKey];
				if (boxAttributeType) {
					// The attribute value (set to the default value initially)
					actualAttributeValue = boxAttributeType.defaultValue;

					// Does the box have a value for this attribute type?
					if (Object.prototype.hasOwnProperty.call(attributes, attributeTypeKey)) {
						// Get the value
						actualAttributeValue = attributes[attributeTypeKey]

						// console.log(`found alternate ${attributeTypeKey} = ${actualAttributeValue}`);
					}
				}
			}

			// Do we have an actual attribute value?
			if (actualAttributeValue) {
				// Do they have the attribute value as a property?
				if (Object.prototype.hasOwnProperty.call(inputs, actualAttributeValue)) {
					// Get the box property key
					const boxPropertyKey = inputs[actualAttributeValue].boxPropertyKey;

					// Get the box property value
					const boxPropertyValue = inputs[actualAttributeValue].boxPropertyValue;

					// Set the actual box property value based on the box property key
					// TODO: Refactor this so we have a custom 'setter' for each box property
					let actualBoxPropertyValue = boxPropertyValue
					if ((boxPropertyKey === 'badge1')
						|| (boxPropertyKey === 'badge2')
						|| (boxPropertyKey === 'badge3')
						|| (boxPropertyKey === 'badge4')
						|| (boxPropertyKey === 'badge5')
						|| (boxPropertyKey === 'badge6')
						|| (boxPropertyKey === 'badge7')
						|| (boxPropertyKey === 'badge8')
						|| (boxPropertyKey === 'badge9')
						|| (boxPropertyKey === 'badge10')
						|| (boxPropertyKey === 'badge11')
						|| (boxPropertyKey === 'badge12')
						|| (boxPropertyKey === 'badge13')
						|| (boxPropertyKey === 'badge14')
						|| (boxPropertyKey === 'badge15')
						|| (boxPropertyKey === 'badge16')
						|| (boxPropertyKey === 'badge17')
						|| (boxPropertyKey === 'badge18')
						|| (boxPropertyKey === 'badge19')
						|| (boxPropertyKey === 'badge20')
						|| (boxPropertyKey === 'badge21')
						|| (boxPropertyKey === 'badge22')
						|| (boxPropertyKey === 'badge23')
						|| (boxPropertyKey === 'badge24')
						|| (boxPropertyKey === 'badge25')
						|| (boxPropertyKey === 'badge26')
						|| (boxPropertyKey === 'badge27')
						|| (boxPropertyKey === 'badge28')
						|| (boxPropertyKey === 'badge29')
						|| (boxPropertyKey === 'badge30')
						|| (boxPropertyKey === 'badge31')
						|| (boxPropertyKey === 'badge32')) {
						try {
							actualBoxPropertyValue = JSON.parse(boxPropertyValue)
						} catch (e) {
							// console.log('Invalid badge JSON')
							// console.log(boxPropertyValue)
						}
					}

					// Only set the box property value if it's not empty
					if (boxPropertyValue !== '') {
						// Merge the inputs into the box properties
						Object.assign(updatedBoxProperties,
							boxProperties,
							{
								[boxPropertyKey]: actualBoxPropertyValue,
							}
						);
					}
				}
			}
		}
	}

	return updatedBoxProperties as Properties;
}

const convertStringValueToString = (value: any) => {
	return value !== undefined ? String(value) : "";
};

const convertStringValueToNumberString = (value: any) => {
	let numberInputsValue = Number(value);
	if (isNaN(numberInputsValue)) {
		numberInputsValue = 0;
	}
	return String(numberInputsValue);
}

const convertStringValueToBooleanString = (value: any) => {
	return String(Boolean(value));
}

const convertObjectValueToString = (value: any) => {
	// If the value is an object, try and parse it to a string. Otherwise return the string
	return typeof value === 'object' ? JSON.stringify(value, null, 2) : value;
};

const convertStringtoValueString = (value: string) => {
	return value;
};

const convertStringToValueObject = (value: string) => {
	// The value coming in is assumed to be an object. Let's try and parse it to a string
	// console.log(`value is ${value}`)
	return JSON.parse(value);
};

// TODO: Set the correct types here
export const TYPES: RenderFunctionTypeMap = {
	[RenderFunctionTypeKey.SET_BOX_PROPERTY]: {
		name: "Set Box Property",
		description: "Set a single property of a box",
		renderFunction: setBoxPropertyRenderFunction,
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_PROPERTY_FROM_ATTRIBUTE]: {
		name: "Set Box Property From Attribute",
		description: "Set a single property of a box from an attribute",
		renderFunction: setBoxPropertyFromAttributeRenderFunction,
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_PROPERTIES_FROM_EXPRESSIONS]: {
		name: "Set Box Properties From Expressions",
		description: "Set multiples properties of a box from expressions",
		renderFunction: setBoxPropertiesFromExpressions,
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {
			boxPropertyExpressions: {
				"text": "attr"
			}
		}
	},
	[RenderFunctionTypeKey.SET_BOX_CHILD_LAYOUT_PROPERTY]: {
		name: "Set Box Child Layout Property",
		description: "Set the child layout property of a box",
		renderFunction: setBoxChildLayoutProperty,
		inputsType: RenderFunctionInputsType.STRING,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_TEXT_PROPERTY]: {
		name: "Set Box Text Property",
		description: "Set the text property of a box",
		renderFunction: setBoxTextProperty,
		inputsType: RenderFunctionInputsType.STRING,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_TEXT_COLOR_PROPERTY]: {
		name: "Set Box Text Color Property",
		description: "Set the text color property of a box",
		renderFunction: setBoxTextColorProperty,
		inputsType: RenderFunctionInputsType.COLOR,
		toDisplayString: convertStringValueToNumberString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_TEXT_SIZE_IN_PIXELS_PROPERTY]: {
		name: "Set Box Text Size (in pixels) Property",
		description: "Set text size (in pixels) property of a box",
		renderFunction: setBoxTextSizeInPixelsProperty,
		inputsType: RenderFunctionInputsType.NUMBER,
		toDisplayString: convertStringValueToNumberString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_TEXT_SIZING_PROPERTY]: {
		name: "Set Box Text Sizing Property",
		description: "Set text sizing property of a box",
		renderFunction: setBoxTextSizingProperty,
		inputsType: RenderFunctionInputsType.STRING,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_TEXT_ALIGNMENT_PROPERTY]: {
		name: "Set Box Text Alignment Property",
		description: "Set text alignment property of a box",
		renderFunction: setBoxTextAlignmentProperty,
		inputsType: RenderFunctionInputsType.STRING,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_TEXT_ALIGNMENT_PADDING_IN_PIXELS_PROPERTY]: {
		name: "Set Box Text Alignment Padding Property",
		description: "Set text alignment padding property of a box",
		renderFunction: setBoxTextAlignmentPaddingInPixelsProperty,
		inputsType: RenderFunctionInputsType.NUMBER,
		toDisplayString: convertStringValueToNumberString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_TEXT_IS_VERTICAL_PROPERTY]: {
		name: "Set Box Text Is Vertical Property",
		description: "Set text is vertical property of a box",
		renderFunction: setBoxTextIsVerticalProperty,
		inputsType: RenderFunctionInputsType.BOOLEAN,
		toDisplayString: convertStringValueToBooleanString,
		toValue: convertStringtoValueString,
		defaultValue: "false"
	},
	[RenderFunctionTypeKey.SET_BOX_FONT_FAMILY_PROPERTY]: {
		name: "Set Box Font Family Property",
		description: "Set the font family property of a box",
		renderFunction: setBoxFontFamilyProperty,
		inputsType: RenderFunctionInputsType.STRING,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_FONT_STYLE_PROPERTY]: {
		name: "Set Box Font Style Property",
		description: "Set the font style property of a box",
		renderFunction: setBoxFontStyleProperty,
		inputsType: RenderFunctionInputsType.STYLE,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_FONT_WEIGHT_PROPERTY]: {
		name: "Set Box Font Weight Property",
		description: "Set the font weight property of a box",
		renderFunction: setBoxFontWeightProperty,
		inputsType: RenderFunctionInputsType.STRING,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_BACKGROUND_COLOR_PROPERTY]: {
		name: "Set Box Background Color Property",
		description: "Set the background color property of a box",
		renderFunction: setBoxBackgroundColorProperty,
		inputsType: RenderFunctionInputsType.COLOR,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_BACKGROUND_IMAGE_PROPERTY]: {
		name: "Set Box Background Image Property",
		description: "Set the background image property of a box",
		renderFunction: setBoxBackgroundImageProperty,
		inputsType: RenderFunctionInputsType.STRING,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_BACKGROUND_IMAGE_POSITION_PROPERTY]: {
		name: "Set Box Background Image Position Property",
		description: "Set the background image position property of a box",
		renderFunction: setBoxBackgroundImagePositionProperty,
		inputsType: RenderFunctionInputsType.STRING,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_BACKGROUND_IMAGE_SIZE_PROPERTY]: {
		name: "Set Box Background Image Size Property",
		description: "Set the background image size property of a box",
		renderFunction: setBoxBackgroundImageSizeProperty,
		inputsType: RenderFunctionInputsType.STRING,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_BACKGROUND_IMAGE_REPEAT_PROPERTY]: {
		name: "Set Box Background Image Repeat Property",
		description: "Set the background image repeat property of a box",
		renderFunction: setBoxBackgroundImageRepeatProperty,
		inputsType: RenderFunctionInputsType.STRING,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_BORDER_COLOR_PROPERTY]: {
		name: "Set Box Border Color Property",
		description: "Set the border color property of a box",
		renderFunction: setBoxBorderColorProperty,
		inputsType: RenderFunctionInputsType.COLOR,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_BORDER_RADIUS_PROPERTY]: {
		name: "Set Box Border Radius Property",
		description: "Set the border radius property of a box",
		renderFunction: setBoxBorderRadiusProperty,
		inputsType: RenderFunctionInputsType.NUMBER,
		toDisplayString: convertStringValueToNumberString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_BORDER_SIZE_IN_PIXELS_PROPERTY]: {
		name: "Set Box Border Size (in pixels) Property",
		description: "Set the border size (in pixels) property of a box",
		renderFunction: setBoxBorderSizeInPixelsProperty,
		inputsType: RenderFunctionInputsType.NUMBER,
		toDisplayString: convertStringValueToNumberString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_BORDER_STYLE_PROPERTY]: {
		name: "Set Box Border Style Property",
		description: "Set the border style property of a box",
		renderFunction: setBoxBorderStyleProperty,
		inputsType: RenderFunctionInputsType.STYLE,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_ATTRIBUTE_TYPE_PROPERTY]: {
		name: "Set Box Badge Type Attribute Property",
		description: "Set badge Type attribute property of a box",
		renderFunction: setBoxBadgeAttributeTypeProperty,
		inputsType: RenderFunctionInputsType.STRING,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_BACKGROUND_COLOR_PROPERTY]: {
		name: "Set Box Badge Background Color Property",
		description: "Set the badge background color property of a box",
		renderFunction: setBoxBadgeBackgroundColorProperty,
		inputsType: RenderFunctionInputsType.COLOR,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_TEXT_COLOR_PROPERTY]: {
		name: "Set Box Badge Text Color Property",
		description: "Set the badge text color property of a box",
		renderFunction: setBoxBadgeTextColorProperty,
		inputsType: RenderFunctionInputsType.COLOR,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_TEXT_PROPERTY]: {
		name: "Set Box Badge Text Property",
		description: "Set the badge text property of a box",
		renderFunction: setBoxBadgeTextProperty,
		inputsType: RenderFunctionInputsType.STRING,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_1_PROPERTY]: {
		name: "Set Box Badge 1 Property",
		description: "Set the badge 1 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge1'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_2_PROPERTY]: {
		name: "Set Box Badge 2 Property",
		description: "Set the badge 2 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge2'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_3_PROPERTY]: {
		name: "Set Box Badge 3 Property",
		description: "Set the badge 3 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge3'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_4_PROPERTY]: {
		name: "Set Box Badge 4 Property",
		description: "Set the badge 4 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge4'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_5_PROPERTY]: {
		name: "Set Box Badge 5 Property",
		description: "Set the badge 5 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge5'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_6_PROPERTY]: {
		name: "Set Box Badge 6 Property",
		description: "Set the badge 6 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge6'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_7_PROPERTY]: {
		name: "Set Box Badge 7 Property",
		description: "Set the badge 7 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge7'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_8_PROPERTY]: {
		name: "Set Box Badge 8 Property",
		description: "Set the badge 8 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge8'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_9_PROPERTY]: {
		name: "Set Box Badge 9 Property",
		description: "Set the badge 9 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge9'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_10_PROPERTY]: {
		name: "Set Box Badge 10 Property",
		description: "Set the badge 10 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge10'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_11_PROPERTY]: {
		name: "Set Box Badge 11 Property",
		description: "Set the badge 11 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge11'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_12_PROPERTY]: {
		name: "Set Box Badge 12 Property",
		description: "Set the badge 12 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge12'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_13_PROPERTY]: {
		name: "Set Box Badge 13 Property",
		description: "Set the badge 13 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge13'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_14_PROPERTY]: {
		name: "Set Box Badge 14 Property",
		description: "Set the badge 14 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge14'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_15_PROPERTY]: {
		name: "Set Box Badge 15 Property",
		description: "Set the badge 15 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge15'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_16_PROPERTY]: {
		name: "Set Box Badge 16 Property",
		description: "Set the badge 16 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge16'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_17_PROPERTY]: {
		name: "Set Box Badge 17 Property",
		description: "Set the badge 17 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge17'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_18_PROPERTY]: {
		name: "Set Box Badge 18 Property",
		description: "Set the badge 18 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge18'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_19_PROPERTY]: {
		name: "Set Box Badge 19 Property",
		description: "Set the badge 19 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge19'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_20_PROPERTY]: {
		name: "Set Box Badge 20 Property",
		description: "Set the badge 20 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge20'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_21_PROPERTY]: {
		name: "Set Box Badge 21 Property",
		description: "Set the badge 21 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge21'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_22_PROPERTY]: {
		name: "Set Box Badge 22 Property",
		description: "Set the badge 22 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge22'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_23_PROPERTY]: {
		name: "Set Box Badge 23 Property",
		description: "Set the badge 23 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge23'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_24_PROPERTY]: {
		name: "Set Box Badge 24 Property",
		description: "Set the badge 24 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge24'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_25_PROPERTY]: {
		name: "Set Box Badge 25 Property",
		description: "Set the badge 25 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge25'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_26_PROPERTY]: {
		name: "Set Box Badge 26 Property",
		description: "Set the badge 26 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge26'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_27_PROPERTY]: {
		name: "Set Box Badge 27 Property",
		description: "Set the badge 27 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge27'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_28_PROPERTY]: {
		name: "Set Box Badge 28 Property",
		description: "Set the badge 28 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge28'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_29_PROPERTY]: {
		name: "Set Box Badge 29 Property",
		description: "Set the badge 29 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge29'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_30_PROPERTY]: {
		name: "Set Box Badge 30 Property",
		description: "Set the badge 30 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge30'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_31_PROPERTY]: {
		name: "Set Box Badge 31 Property",
		description: "Set the badge 31 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge31'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_BADGE_32_PROPERTY]: {
		name: "Set Box Badge 32 Property",
		description: "Set the badge 32 property of a box",
		renderFunction: setBoxBadgePropertyRenderFunc('badge32'),
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_LAYOUT_WEIGHT_PROPERTY]: {
		name: "Set Box Layout Weight Property",
		description: "Set the layout weight property of a box",
		renderFunction: setBoxLayoutWeightProperty,
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {}
	},
	[RenderFunctionTypeKey.SET_BOX_STYLES_PROPERTY]: {
		name: "Set Box Styles Property",
		description: "Set the styles property of a box",
		renderFunction: setBoxStylesProperty,
		inputsType: RenderFunctionInputsType.STYLE,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.SET_BOX_MAXIMUM_GRID_COLUMNS_PROPERTY]: {
		name: "Set Box TypeWriter Mode Cols Property",
		description: "Set the max grid cols property of a box",
		renderFunction: setBoxMaximumGridColumnsProperty,
		inputsType: RenderFunctionInputsType.STRING,
		toDisplayString: convertStringValueToString,
		toValue: convertStringtoValueString,
		defaultValue: ""
	},
	[RenderFunctionTypeKey.MAP_CHOICE_ATTRIBUTE_TO_BOX_PROPERTY]: {
		name: "Map Choice Attribute To Box Property",
		description: "Maps a choice attribute to a single property of a box",
		renderFunction: mapChoiceAttributeToBoxPropertyRenderFunction,
		inputsType: RenderFunctionInputsType.OBJECT,
		toDisplayString: convertObjectValueToString,
		toValue: convertStringToValueObject,
		defaultValue: {
			"True": {
				"boxPropertyKey": "",
				"boxPropertyValue": ""
			},
			"False": {
				"boxPropertyKey": "",
				"boxPropertyValue": "{\"text\": \"\" }"
			}
		}
	},
}

export const getNewRenderFunctionInfoKey = () => uuid();

export const createNewRenderFunctionInfo = (order: number): RenderFunctionInfo => ({
	order,
	type: RenderFunctionTypeKey.SET_BOX_PROPERTY,
	inputs: {},
	mode: RenderFunctionMode.NORMAL
});

export const createDefaultAssociationHighlightRenderFunction = (): RenderFunctionInfo => {
	return {
		"order": 0,
		"type": "setBoxStylesProperty",
		"inputs": "DefaultAssociation",
		"name": "Association Highlight",
		"mode": RenderFunctionMode.ASSOCIATION
	  };
}