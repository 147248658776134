import { PlusOutlined } from "@ant-design/icons";
import * as lensesLib from "@lib/lenses/lenses";
import { Button, Modal } from "antd";
import React, { useState } from "react";
import RightMenuHeading from "../../../organisms/RightMenuHeading";
import RightMenuHeadingText from "../../../organisms/RightMenuHeadingText";
import PowerLensList from "@components/molecules/PowerLensList";
import AddEditPowerLensDialog from "./AddEditPowerLensDialog";
import * as boxTypeLib from "@lib/box/box-type";
import * as boxLib from "@lib/box/box";
import * as attributeTypeLib from "@lib/box/attribute-type";
import * as attributeLib from "@lib/box/attribute";


import { v4 as uuid } from "uuid";
const confirm = Modal.confirm;

export interface PowerLensesMenuProps {
	onEdit: ((lensKey: string, lens: lensesLib.Lens) => void);
	onDelete: (lensKey: string) => void;
	lensesMap: lensesLib.LensMap | undefined;
	canEdit: boolean;
	canDelete: boolean;
	canAdd: boolean;
	onAdd: (lensKey: string, powerLens: lensesLib.Lens) => void;
	boxTypes: boxTypeLib.BoxTypeMap;
	boxes: boxLib.BoxMap;
	attributes: attributeLib.AttributeMap;
}

const PowerLensesMenu: React.FC<PowerLensesMenuProps> = (props) => {
	const [addEditPowerLensDialogIsVisibile, setAddEditPowerLensDialogIsVisibile] = useState(false);
	const [addEditPowerLensDialogIsAdding, setAddEditPowerLensDialogIsAdding] = useState(true);

	const [addEditPowerLens, setAddEditPowerLens] = useState<lensesLib.Lens | null>(null);
	const [addEditPowerLensKey, setAddEditPowerLensKey] = useState<string | null>(null);

	const createNewPowerLens = (): lensesLib.Lens => {
		return {
			name: "",
			longName: "",
			description: "",
			order: 0,
			parameters: {},
			queries: []
		};
	};


	return <>
		{addEditPowerLens ?
			<AddEditPowerLensDialog
				boxTypes={props.boxTypes}
				boxes={props.boxes}
				attributes={props.attributes}
				isAdding={addEditPowerLensDialogIsAdding}
				isVisible={addEditPowerLensDialogIsVisibile}
				onCancelButtonClick={() => { setAddEditPowerLensDialogIsVisibile(false); }}
				// lenses={props.lensMap}
				powerLens={addEditPowerLens}
				onOKButtonClick={(powerLens) => {
					// here we want to hide the form and then tell the parent control that we're adding a group lens

					const newLens = JSON.parse(JSON.stringify(powerLens));
					if (addEditPowerLensKey !== null) {
						if (addEditPowerLensDialogIsAdding) {
							props.onAdd(addEditPowerLensKey, newLens);
						}
						else {
							props.onEdit(addEditPowerLensKey, newLens);
						}
					}

					setAddEditPowerLens(null);
					setAddEditPowerLensKey(null);
					setAddEditPowerLensDialogIsVisibile(false);
				}}
			/> : null}

		<RightMenuHeading>
			<RightMenuHeadingText>Power Lenses</RightMenuHeadingText>
			<Button
				icon={<PlusOutlined />}
				disabled={!(props.canAdd)}
				type="primary"
				style={{
					paddingRight: "12px",
				}}
				onClick={
					() => {
						setAddEditPowerLens(createNewPowerLens());
						setAddEditPowerLensDialogIsAdding(true);
						setAddEditPowerLensKey(uuid());
						setAddEditPowerLensDialogIsVisibile(true);
					}}
			>
				Add
			</Button>
		</RightMenuHeading>
		<div
			style={{
				padding: "12px",
			}}
		>
			<PowerLensList

				onPowerLensEdit={
					(lensKey: string) => {
						if (props.lensesMap !== undefined) {
							setAddEditPowerLens(JSON.parse(JSON.stringify(props.lensesMap[lensKey])));
							setAddEditPowerLensDialogIsAdding(false);
							setAddEditPowerLensKey(lensKey);
							setAddEditPowerLensDialogIsVisibile(true);
						}
					}
				}
				onPowerLensDelete={
					(lensKey: string) => {
						confirm({
							title:
								"Are you sure delete this Lens ?",
							okText: "Yes",
							okType: "danger",
							cancelText: "No",
							zIndex: 9999999,
							onOk: () => { props.onDelete(lensKey) },
							onCancel: () => { },
						});

					}
				}
				lensesMap={
					props.lensesMap
				}
				canEdit={
					props.canEdit
				}
				canDelete={
					props.canDelete
				}
			/>
		</div>
	</>
};

export default PowerLensesMenu;